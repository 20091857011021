import React, { useState, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-regular-svg-icons/faDownload";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons/faFileExport";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons/faSpinner";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";

import classNames from "classnames";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

import axios from "axios";

import { HydraTable, Heading, TimeAgo } from "@peracto/peracto-ui";

import { useConfig } from "@peracto/peracto-config";
import { GET_LIST, useClient } from "@peracto/client";

import { OrderExportForm } from "./OrderExportForm";

export const OrderExportList = () => {
  const [downloadingExport, setDownloadingExport] = useState(null);
  const [showOrderExportForm, setShowOrderExportForm] = useState(false);

  const { client } = useClient();

  const config = useConfig();
  const API_URL = config.get("api");

  const formatDate = config.get("user_format_date", config.get("format_date"));

  const onDownloadOrderExport = async (id) => {
    setDownloadingExport(id);

    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      const { data } = await axios.get(
        `${API_URL}/export-logs/${id}/pre-signed-url`,
        {
          headers,
        }
      );

      const s3Url = data?.preSignedUrl;

      if (s3Url) {
        window.location = s3Url;
      }
    } catch (e) {
      console.error(e);
      toast.error(
        e?.error?.body?.hasOwnProperty("hydra:description")
          ? e.error.body["hydra:description"]
          : "Whoops, there was a problem..."
      );
    } finally {
      setDownloadingExport(null);
    }
  };

  // The 'selectable' property is used to define if the
  // column is available in the 'manage columns' dropdown
  const columns = useMemo(
    () => [
      {
        Header: "Email",
        accessor: "user",
        disableSorting: true,
        Cell: (row) => row.cell.value?.email || "-",
        selectable: false,
      },
      {
        Header: "Created",
        accessor: "createdAt",
        disableSorting: true,

        Cell: ({ cell: { value } }) => {
          const date = new Date(value);
          const validDate = !!date.getDate() === true;

          return value && validDate ? (
            <TimeAgo title={formatDate(new Date(value))} date={value} />
          ) : (
            "-"
          );
        },

        selectable: false,
      },

      {
        Header: "Status",
        accessor: "status",
        sortType: "basic",
        disableSorting: true,

        selectable: false,
        Cell: (row) => {
          return (
            <span
              className={classNames(
                "badge",
                "badge-pill",
                "mr-1",
                "capitalize",
                {
                  "badge-warning": row.cell.value === "processing",
                  "badge-success": row.cell.value === "complete",
                  "badge-danger": row.cell.value === "error",
                }
              )}
            >
              {row.cell.value}
            </span>
          );
        },
      },

      {
        Header: "Row Count",
        accessor: "rowCount",
        sortType: "basic",
        disableSorting: true,
        Cell: ({ cell: { value } }) => (value ? value : "0"),
      },

      {
        Header: () => (
          <span className="inline-block w-full text-right">Download</span>
        ),
        id: "id",
        disableSorting: true,
        accessor: "firstName",
        sortType: "basic",

        Cell: (row) => {
          const id = row.row.original.originId;
          const disabled = row.row.original.status !== "complete";
          const downloadInitialised = downloadingExport === id;

          return (
            <div className="text-right">
              <button
                className="btn btn-sm btn-outline-primary"
                disabled={!!downloadingExport || disabled}
                onClick={(e) => {
                  e.preventDefault();
                  if (!disabled) {
                    onDownloadOrderExport(id);
                  }
                }}
              >
                <FontAwesomeIcon
                  icon={downloadInitialised ? faSpinner : faDownload}
                  {...(downloadInitialised && {
                    spin: true,
                  })}
                  className="mr-2"
                />
                Download
              </button>
            </div>
          );
        },
        selectable: false,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [downloadingExport, formatDate]
  );

  const onFetchData = (options) => {
    return client(GET_LIST, "export-logs", {
      ...options,
      filter: {
        type: "orders",
        ...options.filter,
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>Order Exports | Peracto</title>
      </Helmet>
      <Heading name="Order Exports">
        <div className="ml-2 d-flex align-items-center">
          {showOrderExportForm ? (
            <button
              className="btn btn-outline-primary"
              data-testid="export-user"
              onClick={() => setShowOrderExportForm(false)}
            >
              <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
              Back
            </button>
          ) : (
            <button
              className="btn btn-primary"
              data-testid="export-user"
              onClick={() => setShowOrderExportForm(true)}
            >
              <FontAwesomeIcon icon={faFileExport} className="mr-2" />
              Generate New Export
            </button>
          )}
        </div>
      </Heading>

      {showOrderExportForm ? (
        <OrderExportForm setShowOrderExportForm={setShowOrderExportForm} />
      ) : (
        <div className="row">
          <div className="col">
            <div className="card card--transparent">
              <div className="card-body">
                <HydraTable
                  columns={columns}
                  onFetchData={onFetchData}
                  defaultFilters={{
                    createdAt: "",
                    status: "",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
