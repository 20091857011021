import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useConfig } from "@peracto/peracto-config";
import dayjs from "dayjs";
import { Form, Group, DateRange } from "@peracto/peracto-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons/faFileExport";

export const FormExportForm = (props) => {
  const { formId, setShowFormExportForm } = props;
  const config = useConfig();
  const API_URL = config.get("api");

  const [submitting, setSubmitting] = useState(false);

  const onCreateFormExport = async (values) => {
    setSubmitting(true);
    const { after, before } = values;
    const formattedAfter = after
      ? `${dayjs(after).format("YYYY-MM-DD")}T00:00:00`
      : "";
    const formattedBefore = before
      ? `${dayjs(before).format("YYYY-MM-DD")}T00:00:00`
      : "";

    const body = [
      {
        type: "formId",
        value: formId.toString(),
      },
    ];

    if (formattedAfter && formattedBefore) {
      body.push({
        type: "submittedAt",
        value: `{"after":"${formattedAfter}", "before":"${formattedBefore}"}`,
      });
    }

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    try {
      await axios.post(
        `${API_URL}/export-logs`,
        {
          type: "form-submissions",
          options: body,
        },
        {
          headers,
        }
      );

      toast.success("Form export generating");
    } catch (e) {
      console.error(e);

      toast.error(
        e?.error?.body?.hasOwnProperty("hydra:description")
          ? e.error.body["hydra:description"]
          : "There was an error when trying to create a form export."
      );
    } finally {
      setSubmitting(false);
      setShowFormExportForm(false);
    }
  };
  return (
    <Form
      autoComplete="off"
      values={{
        after: "",
        before: "",
      }}
      showActionPanel={false}
      onSubmit={onCreateFormExport}
    >
      <Group
        key="generate-form-export"
        id="generate-form-export"
        name="Generate Form Export"
      >
        <DateRange
          fromLabel="Start Date"
          toLabel="End Date"
          toName="before"
          fromName="after"
          help="Date range is limited to 7 days"
          testId="date-range"
        />

        <button
          className="btn btn-success"
          data-testid="submit"
          type="submit"
          disabled={submitting}
        >
          <FontAwesomeIcon icon={faFileExport} className="mr-2" />
          Generate New Export
        </button>
      </Group>
    </Form>
  );
};
