import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/pro-regular-svg-icons/faPlay";
import { faTasks } from "@fortawesome/pro-regular-svg-icons/faTasks";

import classNames from "classnames";
import { Helmet } from "react-helmet";

import { HydraTable, Heading } from "@peracto/peracto-ui";

import { useConfig } from "@peracto/peracto-config";
import { GET_LIST, useClient } from "@peracto/client";

import { onRunTask } from "../util";

const TasksList = () => {
  // Default visible columns - key = column accessor
  const [selectedColumns, onSelectColumn] = useState({});

  const { client } = useClient();

  const config = useConfig();
  const API_URL = config.get("api");

  // The 'selectable' property is used to define if the
  // column is available in the 'manage columns' dropdown
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => [
    {
      Header: "Name",
      accessor: "name",
      sortType: "basic",
      Cell: (row) => <Link to={row.row.original.id}>{row.cell.value}</Link>,
      selectable: false,
    },
    {
      Header: "Status",
      accessor: "active",
      sortType: "basic",
      Cell: (row) => (
        <span
          className={classNames("badge", "badge-pill", {
            "badge-success": row.cell.value,
            "badge-danger": !row.cell.value,
          })}
        >
          {row.cell.value ? "Active" : "Inactive"}
        </span>
      ),
      selectable: false,
    },
    {
      Header: "Run Task",
      id: "runTask",
      Cell: (row) => (
        <button
          className="px-0 btn btn-link"
          onClick={() => onRunTask(API_URL, row.row.original.code)}
        >
          <FontAwesomeIcon icon={faPlay} />
        </button>
      ),
      selectable: false,
    },
  ]);

  const onFetchData = (options) => client(GET_LIST, "tasks", options);

  return (
    <>
      <Helmet>
        <title>Tasks | Peracto</title>
      </Helmet>

      <Heading name="Tasks">
        <div className="ml-2 d-flex align-items-center">
          <Link className="btn btn-primary" to="/tasks/task-runs">
            <FontAwesomeIcon icon={faTasks} className="mr-2" />
            View Task Runs
          </Link>
        </div>
      </Heading>

      <div className="row">
        <div className="col">
          <div className="card card--transparent">
            <div className="card-body">
              <HydraTable
                columns={columns}
                selectedColumns={selectedColumns}
                onFetchData={onFetchData}
                onSelectColumn={onSelectColumn}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TasksList;
