import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";

import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

import {
  Heading,
  NotFoundMessage,
  UnauthorisedMessage,
} from "@peracto/peracto-ui";
import { GET_ONE, UPDATE, useClient } from "@peracto/client";
import { convertToTreeData, convertToAPIData } from "./util";

const NavigationEditContainer = ({ name, children }) => {
  return (
    <div className="form-container">
      <Heading name={name ? name : "Loading..."}>
        <div className="flex-grow-1 d-flex align-items-center justify-content-end">
          <Link className="btn btn-outline-primary" to="/menus">
            <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
            Back to Navigation
          </Link>
        </div>
      </Heading>
      {children}
    </div>
  );
};

const NavigationEdit = ({
  NavigationForm,
  location: { pathname },
  match: {
    params: { menuCode },
  },
}) => {
  const { client } = useClient();
  const [loading, setLoading] = useState(true);
  const [unauthorised, setUnauthorised] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [redirect, setRedirect] = useState();
  const [navigationData, setNavigationData] = useState();
  const [savingOrder, setSavingOrder] = useState();

  const fetchNavigation = async () => {
    try {
      const { data, response } = await client(GET_ONE, "menus", {
        id: pathname,
      });

      if (response.status === 404) {
        setRedirect("/menus");
        setLoading(false);
        return;
      }

      const formattedData = {
        ...data,
        menuItems: convertToTreeData(data.menuItems),
      };

      setNavigationData(formattedData);
      setLoading(false);
    } catch (e) {
      console.error(e);

      if (e.status === 403) {
        setUnauthorised(true);
      }

      if (e.status === 404) {
        setNotFound(true);
      }

      setRedirect("/menus");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNavigation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSaveOrder = async (data) => {
    setSavingOrder(true);
    const menuItems = convertToAPIData(data);
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await client(UPDATE, "menus", {
        id: pathname,
        data: {
          menuItems,
        },
      });

      toast.success("Display order saved!");
      setSavingOrder(false);
    } catch (e) {
      console.error(e);
      toast.error(
        e?.error?.body?.hasOwnProperty("hydra:description")
          ? e.error.body["hydra:description"]
          : "Whoops, there was a problem..."
      );
      setSavingOrder(false);
    }
  };

  if (loading) {
    return (
      <NavigationEditContainer>
        <div className="card">
          <div className="card-body">Loading...</div>
        </div>
      </NavigationEditContainer>
    );
  }

  if (unauthorised) {
    return <UnauthorisedMessage />;
  }

  if (notFound) {
    return (
      <NotFoundMessage
        url="/menus"
        message="The menu item you're looking for could not be found"
        buttonLabel="Go to Navigation"
      />
    );
  }

  return (
    <NavigationEditContainer name={navigationData?.name || "Navigation"}>
      {redirect ? (
        <Redirect to={redirect} />
      ) : (
        <>
          <Helmet>
            <title>
              {navigationData?.name || "Navigation"} | Edit | Peracto
            </title>
          </Helmet>
          <NavigationForm
            values={navigationData.menuItems}
            menuCode={menuCode}
            onSaveOrder={onSaveOrder}
            savingOrder={savingOrder}
          />
        </>
      )}
    </NavigationEditContainer>
  );
};

export default NavigationEdit;
