import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

export const DatePickerInput = styled.div`
    width: 100%;

    ${breakpoint('laptop')`
        width: auto;
    `};
`

export const DatePickerContainer = styled.div`
    .react-datepicker {
        font-family: 'Poppins', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }

    .react-datepicker-wrapper {
        width: 100%;
    }
`
