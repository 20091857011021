import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import {
  HydraTable,
  Heading,
  TextFilter,
  DateRangeFilter,
  StyledCheckbox,
  TimeAgo,
} from "@peracto/peracto-ui";

import { useConfig } from "@peracto/peracto-config";
import { GET_LIST, useClient } from "@peracto/client";

const FormSubmissionsList = () => {
  const { client } = useClient();

  const config = useConfig();
  const formatDate = config.get("user_format_date", config.get("format_date"));

  // The 'selectable' property is used to define if the
  // column is available in the 'manage columns' dropdown
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => [
    {
      id: "selection",
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <StyledCheckbox {...getToggleAllRowsSelectedProps()} />
      ),
      Cell: ({ row }) => (
        <StyledCheckbox
          {...row.getToggleRowSelectedProps()}
          data-testid={`row-${row.index}-checkbox`}
        />
      ),
      selectable: false,
    },
    {
      Header: "Name",
      accessor: (row) => {
        return row.form.label;
      },
      id: "form.label",
      sortType: "basic",
      Filter: (props) => <TextFilter label="Label" {...props} />,
      Cell: (row) => (
        <Link
          to={`${window.location.pathname}/${
            row.row.original.originId || row.row.original.id
          }`}
        >
          {row.cell.value}
        </Link>
      ),
      selectable: false,
    },
    {
      Header: "Date Submitted",
      accessor: "submittedAt",
      sortType: "basic",
      Filter: (props) => <DateRangeFilter label="Date Submitted" {...props} />,
      Cell: ({ cell: { value } }) =>
        value ? (
          <TimeAgo title={formatDate(new Date(value))} date={value} />
        ) : (
          "-"
        ),
      selectable: false,
    },
  ]);

  const onFetchData = (options) =>
    client(GET_LIST, "form-submissions", options);

  return (
    <>
      <Helmet>
        <title>Form Submissions | Peracto</title>
      </Helmet>
      <Heading name="Form Submissions"></Heading>

      <div className="row">
        <div className="col">
          <div className="card card--transparent">
            <div className="card-body">
              <HydraTable columns={columns} onFetchData={onFetchData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormSubmissionsList;
