import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { HydraTable, Heading, TextFilter } from "@peracto/peracto-ui";

import { GET_LIST, useClient } from "@peracto/client";

const NavigationList = () => {
  // Default visible columns - key = column accessor
  const [selectedColumns, onSelectColumn] = useState({});

  const { client } = useClient();

  // The 'selectable' property is used to define if the
  // column is available in the 'manage columns' dropdown
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => [
    {
      Header: "Name",
      accessor: "name",
      sortType: "basic",
      Filter: (props) => <TextFilter label="Name" {...props} />,
      Cell: (row) => (
        <Link to={`/menus/${row.row.original.code}`}>{row.cell.value}</Link>
      ),
      selectable: false,
    },
    {
      Header: "Locale",
      accessor: "locale",
      sortType: "basic",
      Filter: (props) => <TextFilter label="Locale" {...props} />,
      selectable: false,
    },
  ]);

  const onFetchData = (options) => client(GET_LIST, "menus", options);

  return (
    <>
      <Helmet>
        <title>Navigation | Peracto</title>
      </Helmet>
      <Heading name="Navigation" />

      <div className="row">
        <div className="col">
          <div className="card card--transparent">
            <div className="card-body">
              <HydraTable
                columns={columns}
                selectedColumns={selectedColumns}
                onFetchData={onFetchData}
                onSelectColumn={onSelectColumn}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavigationList;
