import React, { useMemo } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";
import {
  HydraTable,
  Heading,
  StyledCheckbox,
  TimeAgo,
} from "@peracto/peracto-ui";
import { GET_LIST, useClient } from "@peracto/client";
import { useConfig } from "@peracto/peracto-config";
import { useLocation } from "react-router-dom";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons";
import { Helmet } from "react-helmet";

const FormsSubmitted = ({
  match: {
    params: { label },
  },
}) => {
  const { client } = useClient();
  const config = useConfig();
  let pathname = useLocation().pathname;
  const location = useLocation();
  const id = location.state?.id || null;
  const match = useRouteMatch();

  const formatDate = config.get("user_format_date", config.get("format_date"));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => [
    {
      id: "selection",
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <StyledCheckbox {...getToggleAllRowsSelectedProps()} />
      ),
      Cell: ({ row }) => (
        <StyledCheckbox
          {...row.getToggleRowSelectedProps()}
          data-testid={`row-${row.index}-checkbox`}
        />
      ),
      selectable: false,
      disableSorting: true,
    },

    {
      Header: "Email",
      accessor: "user",
      sortType: "basic",
      Cell: (row) =>
        row.row.original.values.find(
          (item) => item.formField.inputType === "email"
        ).fieldValue,
      selectable: false,
      disableSorting: true,
    },

    {
      Header: "Date Submitted",
      accessor: "submittedAt",
      sortType: "basic",
      disableSorting: true,

      Cell: ({ cell: { value } }) =>
        value ? (
          <TimeAgo title={formatDate(new Date(value))} date={value} />
        ) : (
          "-"
        ),
      selectable: false,
    },
    {
      Header: "View",
      accessor: "view",
      disableSorting: true,

      Cell: ({ row }) => {
        console.log("🚀 ~ row:", row);
        return (
          <Link
            to={{
              pathname: `${match.url}/${row.original.originId}`,
              state: { from: pathname, id: id },
            }}
          >
            View
          </Link>
        );
      },
      selectable: false,
    },
  ]);

  const onFetchData = (options) =>
    client(GET_LIST, `form-submissions?form.label=${label}`, options);

  return (
    <>
      <Helmet>
        <title>Form Submissions | Peracto</title>
      </Helmet>
      <div data-testid="form-submissions">
        <Heading name={`Form Submissions (${label.replace(/\+/g, " ")})`}>
          <div className="flex-grow-1 d-flex align-items-center justify-content-end gap-x-2">
            <Link
              className=" btn btn-primary"
              to={{
                pathname: `/forms/submitted/${label}/export`,
                state: { id: id },
              }}
            >
              <FontAwesomeIcon icon={faFileExport} className="mr-2" />
              Exports
            </Link>
            <Link className="btn btn-outline-primary" to="/forms">
              <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
              Back to Forms
            </Link>
          </div>
        </Heading>
      </div>
      <HydraTable columns={columns} onFetchData={onFetchData} />
    </>
  );
};

export default FormsSubmitted;
