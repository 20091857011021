import { Accordion, Carousel } from "@peracto/peracto-blocks-tailwind";

import Container from "./Container";
import Cards from "./Cards";
import Products from "./Products";
import Departments from "./Departments";
import Brands from "./Brands";
import ProductList from "./ProductList";
import Embed from "./Embed";

export default {
    plugins: {
        content: [
            ProductList,
            Carousel,
            Accordion,
            Products,
            Cards,
            Departments,
            Brands,
            Embed,
        ],
        layout: [Container],
    },
};
