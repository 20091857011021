import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons/faPlusCircle";

import { Helmet } from "react-helmet";

import {
  HydraTable,
  Heading,
  TextFilter,
  StyledCheckbox,
} from "@peracto/peracto-ui";

import { GET_LIST, useClient } from "@peracto/client";

import { inputTypeOptions } from "packages/peracto-common";

const FormFieldList = () => {
  const { client } = useClient();

  // The 'selectable' property is used to define if the
  // column is available in the 'manage columns' dropdown
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => [
    {
      id: "selection",
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <StyledCheckbox {...getToggleAllRowsSelectedProps()} />
      ),
      Cell: ({ row }) => (
        <StyledCheckbox
          {...row.getToggleRowSelectedProps()}
          data-testid={`row-${row.index}-checkbox`}
        />
      ),
      selectable: false,
    },
    {
      Header: "Label",
      accessor: "label",
      sortType: "basic",
      Filter: (props) => <TextFilter label="Label" {...props} />,
      Cell: (row) => <Link to={row.row.original.id}>{row.cell.value}</Link>,
      selectable: false,
    },
    {
      Header: "Input Type",
      accessor: "inputType",
      sortType: "basic",
      Filter: (props) => <TextFilter label="Input Type" {...props} />,
      Cell: (row) =>
        inputTypeOptions.find((option) => row.cell.value === option.value)
          ?.label || "-",
      selectable: false,
    },
  ]);

  const onFetchData = (options) => client(GET_LIST, "form-fields", options);

  return (
    <>
      <Helmet>
        <title>Form Fields | Peracto</title>
      </Helmet>
      <Heading name="Form Fields">
        <div className="ml-2 d-flex align-items-center">
          <Link
            className="btn btn-primary"
            to="/form-fields/add"
            data-testid="add-form-field"
          >
            <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
            Add Form Field
          </Link>
        </div>
      </Heading>

      <div className="row">
        <div className="col">
          <div className="card card--transparent">
            <div className="card-body">
              <HydraTable
                columns={columns}
                onFetchData={onFetchData}
                defaultFilters={{
                  label: "",
                  inputType: "",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormFieldList;
