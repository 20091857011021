/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import {
  Form,
  Group,
  Input,
  Select,
  Modal,
  FormActions,
} from "@peracto/peracto-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons/faExclamationTriangle";

import { toast } from "react-toastify";

import { DELETE, useClient } from "@peracto/client";

export const MODE_ADD = "add";
export const MODE_EDIT = "edit";

const RedirectsForm = ({ mode = MODE_EDIT, values, ...props }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [redirect, setRedirect] = useState();

  const { client } = useClient();

  const partialMatchOptions = [
    { label: "Regular Expression", value: "true" },
    { label: "Exact", value: "false" },
  ];

  const redirectTypeOptions = [
    { label: "301 (Permanent)", value: 301 },
    { label: "302 (Temporary)", value: 302 },
  ];

  const statusOptions = [
    { label: "Active", value: "true" },
    { label: "Inactive", value: "false" },
  ];

  const storefrontURL =
    process.env.REACT_APP_STOREFRONT_URL || window.location.origin;

  const onDelete = async () => {
    try {
      await client(DELETE, "redirects", {
        id: values.id,
      });

      toast.success("Redirect deleted successfully!");
      setRedirect("/redirects");
    } catch (e) {
      console.error(e);
      toast.error(
        e?.error?.body?.hasOwnProperty("hydra:description")
          ? e.error.body["hydra:description"]
          : "Whoops, there was a problem..."
      );
    }
  };

  return (
    <>
      {redirect && <Redirect to={redirect} />}

      {mode === MODE_EDIT && (
        <FormActions>
          <a
            className="text-danger"
            data-testId="deleteRedirect"
            onClick={() => setShowDialog(true)}
          >
            <FontAwesomeIcon icon={faTrash} className="mr-2" />
            Delete Redirect
          </a>
        </FormActions>
      )}

      <Form
        autoComplete="off"
        values={{
          partialMatch: "false",
          code: 301,
          active: "true",
          ...values,
        }}
        {...props}
      >
        <Group key="core" id="core" name="Core">
          <Input
            name="fromUrl"
            label="From Path"
            placeholder="Enter From Path..."
            required
            help={`This is the path that will be redirected from relative to ${storefrontURL}. 
                            Where special characters are present ensure they are appropriately encoded.`}
          />

          <Select
            name="partialMatch"
            label="How should the from path be matched?"
            placeholder="Please select..."
            options={partialMatchOptions}
            help="Exact matches will always take priority.
                        Regular expression rules should only be used by advanced users. 
                        Validate your Regular expression using https://regexr.com/."
            testId="PathMatch"
          />

          <Input
            name="toUrl"
            label="To Path / URL"
            placeholder="Enter To Path/URL..."
            required
            help={`For internal redirects set relative to ${storefrontURL}; to redirect to the homepage set to '/'.
                        For external redirects use the full URL including https:// or http://`}
            testId="toPath"
          />

          <Select
            name="code"
            label="Type"
            placeholder="Please select..."
            options={redirectTypeOptions}
            testId="type"
          />

          <Select
            name="active"
            label="Status"
            placeholder="Please select..."
            options={statusOptions}
            testId="status"
          />
        </Group>
      </Form>

      {mode === MODE_EDIT && (
        <Modal
          isVisible={showDialog}
          title="Delete Redirect"
          close={() => setShowDialog(false)}
          buttons={[
            {
              type: "btn-outline-secondary",
              text: "Close",
              action: () => setShowDialog(false),
            },
            {
              type: "btn-danger",
              text: "Delete Redirect",
              action: () => onDelete(),
            },
          ]}
        >
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            size="4x"
            className="mb-4 d-block"
          />
          Are you sure you would like to permanently delete this redirect?
          Deleted redirects cannot be recovered.
        </Modal>
      )}
    </>
  );
};

RedirectsForm.displayName = "RedirectsForm";

RedirectsForm.propTypes = {
  values: PropTypes.object,
  mode: PropTypes.oneOf([MODE_ADD, MODE_EDIT]),
  schema: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default RedirectsForm;
