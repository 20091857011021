import React, { useMemo } from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode } from "@fortawesome/pro-regular-svg-icons/faCode";
import { Flex } from "@chakra-ui/core";

const IFrameContent = styled.div`
    position: relative;

    input {
        width: 100%;
        padding: 9px 16px;
        color: #333;
        border: 1px solid #d8dada;
        border-radius: 4px;
        font-size: 14px;
        margin-bottom: 16px;

        &:focus: {
            outline: none;
            border-color: rgb(147, 197, 253);
        }
    }

    button {
        border-radius: 32px;
        font-weight: 700;
        display: inline-flex;
        justify-items: center;
        transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
        border: 1px solid transparent;
        background-color: #0033a0;
        color: #fff;
        font-size: 14px;
        padding: 10px 24px;

        &:hover {
            background-color: #002980;
        }

        &:active {
            background-color: #001f60;
        }
    }
`;

const IFrameOverlay = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
`;

let Icon = () => (
    <Flex py={10} justifyContent="center" alignItems="center" w="100%">
        <FontAwesomeIcon icon={faCode} size="6x" />
    </Flex>
);

const EmbedComponent = ({ state: { url }, isEditMode }) => {
    const inStorefront = !window?.location?.pathname.includes("/content/edit/");

    return (
        <IFrameContent className="content__embed">
            {url && url.length > 0 ? (
                <>
                    <Content url={url} />
                    {isEditMode && <IFrameOverlay />}
                </>
            ) : inStorefront ? (
                <></>
            ) : (
                <Icon />
            )}
        </IFrameContent>
    );
};

const Content = ({ url }) => {
    return useMemo(
        () => (
            <div
                className="embed-iframe"
                dangerouslySetInnerHTML={{ __html: url }}
            />
        ),
        [url]
    );
};

export default EmbedComponent;
