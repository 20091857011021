/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { Field, FieldArray } from "formik";
import AsyncSelect from "react-select/async";
import debounce from "debounce-promise";
import Sortable from "react-sortablejs";

import {
  Form,
  Group,
  Input,
  Modal,
  FormActions,
  SortableRow,
  Checkbox,
  Textarea,
  Error,
} from "@peracto/peracto-ui";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faGripLines } from "@fortawesome/pro-regular-svg-icons/faGripLines";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons/faExclamationTriangle";

import { toast } from "react-toastify";

import { DELETE, GET_LIST, useClient } from "@peracto/client";

export const MODE_ADD = "add";
export const MODE_EDIT = "edit";

const FormsForm = ({ mode = MODE_EDIT, ...props }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [redirect, setRedirect] = useState();
  const [formFields, setFormFields] = useState();

  const { client } = useClient();

  const fetchFormFields = async (inputValue, fieldVal, email = false) => {
    const filter = {
      label: inputValue,
    };

    if (email) {
      filter.inputType = "email";
    }

    const { data } = await client(GET_LIST, "form-fields", {
      id: "form-fields",
      filter,
    });

    setFormFields(data);

    const selectedValues = fieldVal ? fieldVal : [];
    const selectedIDs = selectedValues.map((val) => val.formField["@id"]);

    let values = data.filter((attr) => !selectedIDs.includes(attr.id));

    return values.map((attr) => ({
      label: `${attr.label}`,
      value: attr.id,
    }));
  };

  const debouncedFetchFormFields = debounce(fetchFormFields, 200);

  const onDelete = async () => {
    try {
      await client(DELETE, "forms", {
        id: props.values.id,
      });

      toast.success("Form deleted successfully!");
      setRedirect("/forms");
    } catch (e) {
      console.error(e);
      toast.error(
        e?.error?.body?.hasOwnProperty("hydra:description")
          ? e.error.body["hydra:description"]
          : "Whoops, there was a problem..."
      );
    }
  };

  return (
    <>
      {redirect && <Redirect to={redirect} />}

      {mode === MODE_EDIT && (
        <FormActions>
          <a
            className="text-danger"
            onClick={() => setShowDialog(true)}
            data-testid="delete-form"
          >
            <FontAwesomeIcon icon={faTrash} className="mr-2" />
            Delete Form
          </a>
        </FormActions>
      )}

      <Form
        autoComplete="off"
        {...props}
        values={{
          label: "",
          formFieldLinks: [],
          submitButtonText: "",
          redirectUrl: "",
          thirdPartyFormId: "",
          sendAdminEmail: false,
          adminEmailRcpt: "",
          adminEmailSubject: "",
          adminEmailContent: "",
          sendUserEmail: false,
          userEmailField: "",
          userEmailSubject: "",
          userEmailContent: "",
          ...props.values,
        }}
      >
        <Group key="core" id="core" name="Core">
          <div data-testid="label-section">
            <Input
              name="label"
              label="Label"
              testId="label"
              placeholder="Enter Label..."
              required
            />
          </div>

          <div data-testid="form-field-section">
            <Field name="formFieldLinks">
              {({ field, form }) => {
                return (
                  <>
                    <FieldArray name={field.name}>
                      {({ move }) => (
                        <div className="form-group">
                          <label className="mb-1">Form Fields</label>
                          <table className="table table-sm">
                            <colgroup>
                              <col width="10%" />
                              <col width="60%" />
                              <col width="15%" />
                              <col width="15%" />
                            </colgroup>

                            <thead>
                              <tr>
                                <th></th>
                                <th>Field</th>
                                <th>Mandatory</th>
                                <th className="text-right">Remove</th>
                              </tr>
                            </thead>

                            <Sortable
                              tag="tbody"
                              onChange={(order, sortable, evt) => {
                                move(evt.oldIndex, evt.newIndex);
                              }}
                              options={{
                                animation: 100,
                                handle: ".handle",
                              }}
                            >
                              {field.value && field.value.length > 0 ? (
                                field.value.map((item, index) => {
                                  return (
                                    <SortableRow key={index} data-id={index}>
                                      <td>
                                        <span
                                          className="handle"
                                          data-testid={`row-${index}-slider`}
                                        >
                                          <FontAwesomeIcon icon={faGripLines} />
                                        </span>
                                      </td>
                                      <td data-testid={`row-${index}-field`}>
                                        {item?.formField?.label}
                                      </td>
                                      <td>
                                        <Checkbox
                                          name={`${field.name}.${index}.mandatory`}
                                          label=""
                                          className="sortable-checkbox"
                                          onClick={(e) => {
                                            form.setFieldValue(
                                              `${field.name}.${index}.mandatory`,
                                              e.target.checked
                                            );
                                          }}
                                        />
                                      </td>
                                      <td className="text-right">
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-danger"
                                          data-testid={`row-${index}-remove`}
                                          onClick={() => {
                                            const values = [...field.value];
                                            values.splice(index, 1);
                                            form.setFieldValue(field.name, [
                                              ...values,
                                            ]);

                                            // Clear userEmailField too if it's being removed from the form
                                            if (
                                              form.values.userEmailField ===
                                              item.formField.label
                                            ) {
                                              form.setFieldValue(
                                                "userEmailField",
                                                null
                                              );
                                            }
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                      </td>
                                    </SortableRow>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td
                                    colSpan="4"
                                    className="py-2 text-center"
                                    data-testid="placeholder-text"
                                  >
                                    Please select the fields you'd like to add
                                    to the form.
                                  </td>
                                </tr>
                              )}
                            </Sortable>
                          </table>
                        </div>
                      )}
                    </FieldArray>
                    <div className="row">
                      <div className="col-12" data-testid="form-fields">
                        <AsyncSelect
                          className="mb-3 w-100"
                          classNamePrefix="list"
                          loadOptions={(input) =>
                            debouncedFetchFormFields(input, field.value)
                          }
                          isSearchable={true}
                          onChange={(option) => {
                            const selectedValue = formFields.filter(
                              (attr) => attr.id === option.value
                            );
                            const fieldValues = field.value
                              ? [...field.value]
                              : [];
                            fieldValues.push({
                              mandatory: false,
                              formField: selectedValue[0],
                            });
                            form.setFieldValue(field.name, [...fieldValues]);
                          }}
                          value={null}
                          placeholder="Search for Form Fields to add..."
                          noOptionsMessage={({ inputValue }) => {
                            if (inputValue.length > 0) {
                              return `No results found for '${inputValue}'.`;
                            } else {
                              return "Enter text to begin searching.";
                            }
                          }}
                        />
                      </div>
                    </div>
                  </>
                );
              }}
            </Field>
          </div>

          <div data-testid="submit-button-section">
            <Input
              name="submitButtonText"
              label="Submit Button Text"
              testId="submit-btn-text"
              placeholder="Enter Submit Button Text..."
              required
            />
          </div>

          <div data-testid="redirect-section">
            <Input
              name="redirectUrl"
              label="Redirect URL"
              testId="redirect-url"
              placeholder="Enter Redirect URL..."
              help="The URL that users will be redirected to when the form is submitted successfully."
              required
            />
          </div>

          <div data-testid="third-party-section">
            <Input
              name="thirdPartyFormId"
              label="Third Party Form ID"
              testId="third-party-form-id"
              placeholder="Enter Third Party Form ID..."
            />
          </div>

          <div data-testid="admin-email-section">
            <Checkbox name="sendAdminEmail" label="Admin Email" />

            <Input
              showWhen="sendAdminEmail"
              name="adminEmailRcpt"
              label="Admin Email Recipient"
              placeholder="Enter Admin Email Recipient..."
              testId="admin-email-recipient"
            />

            <Input
              showWhen="sendAdminEmail"
              name="adminEmailSubject"
              label="Admin Email Subject"
              placeholder="Enter Admin Email Subject..."
              testId="admin-email-subject"
            />

            <Textarea
              showWhen="sendAdminEmail"
              name="adminEmailContent"
              label="Admin Email Content"
              placeholder="Enter Admin Email Content..."
              testId="admin-email-content"
            />
          </div>

          <div data-testid="user-email-section">
            <Checkbox name="sendUserEmail" label="User Email" />

            <Field name="userEmailField">
              {({ field, form }) => (
                <>
                  {form.values.sendUserEmail && (
                    <>
                      <label className="mb-0">User Email Field</label>

                      <div data-testid="user-email-field">
                        <AsyncSelect
                          className="mb-2 w-100"
                          classNamePrefix="list"
                          loadOptions={(input) =>
                            debouncedFetchFormFields(
                              input,
                              form.values.formFields,
                              true
                            )
                          }
                          isSearchable={true}
                          onChange={(option) => {
                            form.setFieldValue("userEmailField", option.label);
                          }}
                          value={{
                            label: field.value,
                            value: field.value,
                          }}
                          testId="user-email-field"
                          placeholder="Select User Email Field..."
                          noOptionsMessage={({ inputValue }) => {
                            if (
                              inputValue.length > 0 &&
                              inputValue !== field.value
                            ) {
                              return `No results found for '${inputValue}'.`;
                            } else {
                              return "Enter text to begin searching.";
                            }
                          }}
                        />
                      </div>

                      {form.errors[field.name] && (
                        <div className="mb-2">
                          <Error error={form.errors[field.name]} />
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </Field>

            <Input
              showWhen="sendUserEmail"
              name="userEmailSubject"
              label="User Email Subject"
              placeholder="Enter User Email Subject..."
              testId="user-email-subject"
            />

            <Textarea
              showWhen="sendUserEmail"
              name="userEmailContent"
              label="User Email Content"
              placeholder="Enter User Email Content..."
              testId="user-email-content"
            />
          </div>
        </Group>
      </Form>

      {mode === MODE_EDIT && (
        <Modal
          isVisible={showDialog}
          title="Delete Form"
          close={() => setShowDialog(false)}
          buttons={[
            {
              type: "btn-outline-secondary",
              text: "Close",
              action: () => setShowDialog(false),
            },
            {
              type: "btn-danger",
              text: "Delete Form",
              action: () => onDelete(),
            },
          ]}
        >
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            size="4x"
            className="mb-4 d-block"
          />
          Are you sure you would like to permanently delete this form? Deleted
          forms cannot be recovered.
        </Modal>
      )}
    </>
  );
};

FormsForm.displayName = "FormsForm";

FormsForm.propTypes = {
  values: PropTypes.object,
  mode: PropTypes.oneOf([MODE_ADD, MODE_EDIT]),
  schema: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default FormsForm;
