import React from 'react'
import PropTypes from 'prop-types'

import NavigationTree from './NavigationTree'
const NavigationForm = ({ ...props }) => {
    return <NavigationTree {...props} />
}

NavigationForm.displayName = 'NavigationForm'

NavigationForm.propTypes = {
    values: PropTypes.array,
    onSaveOrder: PropTypes.func.isRequired,
}

export default NavigationForm
