import React from "react";
import { Route, Switch } from "react-router-dom";

import PromotionsAdd from "./PromotionsAdd";
import PromotionsEdit from "./PromotionsEdit";
import PromotionsForm from "./PromotionsForm";
import PromotionsList from "./PromotionsList";
import PromotionsMetrics from "./PromotionsMetrics";
export { PromotionsAdd, PromotionsEdit, PromotionsForm, PromotionsList };

const defaults = {
    PromotionsForm,
    PromotionsAdd,
    PromotionsEdit,
    PromotionsList,
};

export default (options = {}) => {
    const components = { ...defaults, ...options };
    const { PromotionsForm, PromotionsList, PromotionsAdd, PromotionsEdit } =
        components;

    return {
        routes: [
            {
                path: "/promotions",
                render: ({ match }) => (
                    <Switch>
                        <Route
                            path={`${match.url}`}
                            exact
                            component={PromotionsList}
                        />
                        <Route path={`${match.url}/add`} exact>
                            {(props) => (
                                <PromotionsAdd
                                    {...props}
                                    PromotionsForm={PromotionsForm}
                                />
                            )}
                        </Route>
                        <Route path={`${match.url}/:promotion`} exact>
                            {(props) => (
                                <PromotionsEdit
                                    {...props}
                                    PromotionsForm={PromotionsForm}
                                />
                            )}
                        </Route>
                    </Switch>
                ),
            },
            {
                path: "/promotion-metrics",
                render: ({ match }) => (
                    <Switch>
                        <Route
                            path={`${match.url}`}
                            exact
                            component={PromotionsMetrics}
                        />
                    </Switch>
                ),
            },
        ],
    };
};
