import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle'
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons/faPlusCircle'

export const NavigationTreeEmptyMessage = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 4rem;
    padding-bottom: 4rem;

    p {
        font-size: 1.1rem;
    }
`

const NavigationTreeEmpty = ({ setModalData, setShowAddModal }) => (
    <NavigationTreeEmptyMessage>
        <FontAwesomeIcon icon={faInfoCircle} size="6x" className="mb-3" />
        <p>No items have been added to this menu</p>
        <button
            className="btn btn-primary"
            onClick={() => {
                setModalData({})
                setShowAddModal(true)
            }}
        >
            <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
            Add Item
        </button>
    </NavigationTreeEmptyMessage>
)

export default NavigationTreeEmpty
