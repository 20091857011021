import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-regular-svg-icons/faDownload";
import { faUpload } from "@fortawesome/pro-regular-svg-icons/faUpload";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons/faPlusCircle";

import classNames from "classnames";
import { Helmet } from "react-helmet";
import download from "downloadjs";
import axios from "axios";

import {
  HydraTable,
  Heading,
  TextFilter,
  DateRangeFilter,
  SelectFilter,
  StyledCheckbox,
  TimeAgo,
} from "@peracto/peracto-ui";

import { useConfig } from "@peracto/peracto-config";
import { GET_LIST, useClient } from "@peracto/client";

const RedirectList = () => {
  // Default visible columns - key = column accessor
  const [selectedColumns, onSelectColumn] = useState({
    hits: false,
    created: false,
  });

  const { client } = useClient();

  const config = useConfig();
  const formatDate = config.get("user_format_date", config.get("format_date"));
  const API_URL = config.get("api");

  // The 'selectable' property is used to define if the
  // column is available in the 'manage columns' dropdown
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => [
    {
      id: "selection",
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <StyledCheckbox {...getToggleAllRowsSelectedProps()} />
      ),
      Cell: ({ row }) => (
        <StyledCheckbox
          {...row.getToggleRowSelectedProps()}
          data-testid={`row-${row.index}-checkbox`}
        />
      ),
      selectable: false,
    },
    {
      Header: "From Path",
      accessor: "fromUrl",
      sortType: "basic",
      Filter: (props) => <TextFilter label="From Path" {...props} />,
      Cell: (row) => (
        <Link
          to={row.row.original.id}
          data-testid={`row-${row.row.index}-fromPath`}
        >
          {row.cell.value}
        </Link>
      ),
      selectable: false,
    },
    {
      Header: "To Path",
      accessor: "toUrl",
      sortType: "basic",
      Filter: (props) => <TextFilter label="To Path" {...props} />,
      selectable: false,
    },
    {
      Header: "Type",
      accessor: "code",
      sortType: "basic",
      Filter: (props) => (
        <SelectFilter
          label="Type"
          options={[
            { value: "301", label: "301" },
            { value: "302", label: "302" },
          ]}
          {...props}
        />
      ),
      selectable: false,
    },
    {
      Header: "Match",
      accessor: "partialMatch",
      sortType: "basic",
      Filter: (props) => (
        <SelectFilter
          label="Match"
          options={[
            { value: "true", label: "Regular Expression" },
            { value: "false", label: "Exact" },
          ]}
          {...props}
        />
      ),
      Cell: (row) => (row.cell.value ? "Regular Expression" : "Exact"),
      selectable: false,
    },
    {
      Header: "Status",
      accessor: "active",
      sortType: "basic",
      Filter: (props) => (
        <SelectFilter
          label="Status"
          options={[
            { value: "true", label: "Active" },
            { value: "false", label: "Inactive" },
          ]}
          {...props}
        />
      ),
      Cell: (row) => (
        <span
          className={classNames("badge", "badge-pill", {
            "badge-success": row.cell.value,
            "badge-danger": !row.cell.value,
          })}
        >
          {row.cell.value ? "Active" : "Inactive"}
        </span>
      ),
      selectable: false,
    },

    {
      Header: "Last Hit",
      accessor: "lastHit",
      sortType: "basic",
      Filter: (props) => <DateRangeFilter label="Last Hit" {...props} />,
      Cell: ({ cell: { value } }) =>
        value ? (
          <TimeAgo title={formatDate(new Date(value))} date={value} />
        ) : (
          "-"
        ),
      selectable: false,
    },
    {
      Header: "Hits",
      accessor: "hits",
      sortType: "basic",
      Filter: (props) => <TextFilter label="Hits" {...props} />,
      selectable: true,
      show: selectedColumns["hits"],
    },
    {
      Header: "Created",
      accessor: "created",
      sortType: "basic",
      Filter: (props) => <DateRangeFilter label="Created" {...props} />,
      Cell: ({ cell: { value } }) =>
        value ? (
          <TimeAgo title={formatDate(new Date(value))} date={value} />
        ) : (
          "-"
        ),
      selectable: true,
      show: selectedColumns["created"],
    },
  ]);

  const onFetchData = (options) => client(GET_LIST, "redirects", options);

  const exportRedirects = async () => {
    const { data } = await axios.get(`${API_URL}/export/redirect`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    download(data, `redirect_export.csv`);
  };

  return (
    <>
      <Helmet>
        <title>Redirects | Peracto</title>
      </Helmet>
      <Heading name="Redirects">
        <div className="ml-2 d-flex align-items-center">
          <button
            className="mr-2 btn btn-link"
            data-testid="export-redirect"
            onClick={() => exportRedirects()}
          >
            <FontAwesomeIcon icon={faDownload} className="mr-2" />
            Export Redirect Data
          </button>

          <Link
            className="mr-2 btn btn-outline-primary"
            data-testid="import-redirect"
            to="/redirects/import"
          >
            <FontAwesomeIcon icon={faUpload} className="mr-2" />
            Import Redirect Data
          </Link>

          <Link
            className="btn btn-primary"
            data-testid="add-redirect"
            to="/redirects/add"
          >
            <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
            Add Redirect
          </Link>
        </div>
      </Heading>

      <div className="row">
        <div className="col">
          <div className="card card--transparent">
            <div className="card-body">
              <HydraTable
                columns={columns}
                selectedColumns={selectedColumns}
                onFetchData={onFetchData}
                onSelectColumn={onSelectColumn}
                defaultFilters={{
                  fromUrl: "",
                  toUrl: "",
                  active: "true",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RedirectList;
