import camelCase from 'lodash/camelCase'

const NAVIGATION_TYPES = [
    'Category',
    'Page',
    'Article',
    'Article Category',
    'Direct Link',
    'Branch',
]

export const navigationTypeOptions = NAVIGATION_TYPES.map(n => ({
    label: n,
    value: n === 'Page' ? 'content' : camelCase(n),
}))

export const directLinkTypeOptions = [
    { label: 'Internal', value: 'internal' },
    { label: 'External', value: 'external' },
]

export const getEndpointsForTypes = (type, input = '') => {
    const types = {
        category: {
            endpoint: 'categories',
            params: {
                id: 'categories',
                filter: {
                    heading: input,
                    status: 'active',
                },
            },
        },
        article: {
            endpoint: 'contents',
            params: {
                id: 'contents',
                filter: {
                    type: 'article',
                    name: input,
                },
            },
        },
        content: {
            endpoint: 'contents',
            params: {
                id: 'contents',
                filter: {
                    type: 'page',
                    name: input,
                },
            },
        },
        articleCategory: {
            endpoint: 'article-categories',
            params: {
                id: 'article-categories',
                filter: {
                    name: input,
                },
            },
        },
    }

    return types[type]
}

export const getKeyForIdentifierLabel = type => {
    switch (type) {
        case 'category':
            return 'heading'
        default:
            return 'name'
    }
}
