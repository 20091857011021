/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";

import { toast } from "react-toastify";
import * as yup from "yup";
import { Helmet } from "react-helmet";
import cloneDeep from "lodash/cloneDeep";

import {
  Heading,
  NotFoundMessage,
  UnauthorisedMessage,
} from "@peracto/peracto-ui";
import {
  GET_ONE,
  UPDATE,
  useClient,
  getSchemaFromResource,
} from "@peracto/client";

const FormsEditContainer = ({ children }) => {
  return (
    <div className="form-container">
      <Heading name="Edit Form">
        <div className="flex-grow-1 d-flex align-items-center justify-content-end">
          <Link
            className="btn btn-outline-primary"
            to="/forms"
            data-testid="back-to-forms"
          >
            <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
            Back to Forms
          </Link>
        </div>
      </Heading>
      {children}
    </div>
  );
};

const FormsEdit = ({ FormsForm, location: { pathname } }) => {
  const { client, getResource } = useClient();
  const [loading, setLoading] = useState(true);
  const [unauthorised, setUnauthorised] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [redirect, setRedirect] = useState();

  const [forms, setForms] = useState();

  const fetchForms = async () => {
    try {
      const { data, response } = await client(GET_ONE, "forms", {
        id: pathname,
      });

      const formData = {
        ...data,
        formFieldLinks: Object.values(data.formFieldLinks),
      };

      setForms(formData);
      setLoading(false);

      if (response.status === 404) {
        setRedirect("/forms");
      }
    } catch (e) {
      console.error(e);

      if (e.status === 403) {
        setUnauthorised(true);
      }

      if (e.status === 404) {
        setNotFound(true);
      }

      setLoading(false);
      setRedirect("/forms");
    }
  };

  useEffect(() => {
    fetchForms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const schema = getSchemaFromResource(getResource("forms")).shape({
    label: yup.string().required(),
    thirdPartyFormId: yup.string().nullable(),
    sendUserEmail: yup.boolean(),
    submitButtonText: yup.string().required(),
    redirectUrl: yup.string().required(),
    adminEmailSubject: yup
      .string()
      .nullable()
      .when("sendAdminEmail", {
        is: true,
        then: yup
          .string()
          .required(
            "Admin email subject must be set when 'Admin Email' is enabled"
          ),
      }),
    adminEmailContent: yup
      .string()
      .nullable()
      .when("sendAdminEmail", {
        is: true,
        then: yup
          .string()
          .required(
            "Admin email content must be set when 'Admin Email' is enabled"
          ),
      }),

    userEmailSubject: yup
      .string()
      .nullable()
      .when("sendUserEmail", {
        is: true,
        then: yup
          .string()
          .required(
            "User email subject must be set when 'User Email' is enabled"
          ),
      }),
    userEmailContent: yup
      .string()
      .nullable()
      .when("sendUserEmail", {
        is: true,
        then: yup
          .string()
          .required(
            "User email content must be set when 'User Email' is enabled"
          ),
      }),
    userEmailField: yup
      .string()
      .nullable()
      .when("sendUserEmail", {
        is: true,
        then: yup
          .string()
          .required(
            "User email field must be set when 'User Email' is enabled"
          ),
      }),
    adminEmailRcpt: yup
      .string()
      .nullable()
      .when("sendAdminEmail", {
        is: true,
        then: yup
          .string()
          .required(
            "Admin email field must be set when 'Admin Email' is enabled"
          ),
      }),
  });

  const onSubmit = async (data, actions) => {
    const dataClone = cloneDeep(data);

    dataClone.formFieldLinks &&
      dataClone.formFieldLinks.forEach((item, idx) => {
        if (!item["@id"]) {
          item.formField = item.formField["@id"];
        } else {
          delete item.formField;
        }
        item.displayOrder = idx + 1;

        item["@type"] && delete item["@type"];
      });

    delete dataClone["@context"];
    delete dataClone["@id"];
    delete dataClone["@type"];
    delete dataClone.id;
    delete dataClone.originId;

    try {
      const { data } = await client(UPDATE, "forms", {
        id: pathname,
        data: dataClone,
      });

      if (data.violations && data.violations.length > 0) {
        // Display errors for invalid fields
        actions.setSubmitting(false);
        data.violations.map((error) => {
          actions.setFieldError(error.propertyPath, error.message);
        });
      } else {
        fetchForms();
        actions.setSubmitting(false);
        toast.success("Form successfully updated!");
      }
    } catch (e) {
      console.error(e);
      if (e?.error?.body?.violations && e.error.body.violations.length > 0) {
        // Display errors for invalid fields
        actions.setSubmitting(false);
        e.error.body.violations.map((error) => {
          actions.setFieldError(error.propertyPath, error.message);
        });
      }
      toast.error(
        e?.error?.body?.hasOwnProperty("hydra:description")
          ? e.error.body["hydra:description"]
          : "Whoops, there was a problem..."
      );
      actions.setSubmitting(false);
      actions.setValues(dataClone);
    }
  };

  if (loading) {
    return (
      <FormsEditContainer>
        <div className="card">
          <div className="card-body">Loading...</div>
        </div>
      </FormsEditContainer>
    );
  }

  if (unauthorised) {
    return <UnauthorisedMessage />;
  }

  if (notFound) {
    return (
      <NotFoundMessage
        url="/forms"
        message="The form you're looking for could not be found"
        buttonLabel="Go to Forms"
      />
    );
  }

  return (
    <FormsEditContainer>
      {redirect && <Redirect to={redirect} />}

      <Helmet>
        <title>{forms.label || "Form"} | Edit | Peracto</title>
      </Helmet>

      <FormsForm values={forms} onSubmit={onSubmit} schema={schema} />
    </FormsEditContainer>
  );
};

export default FormsEdit;
