/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";

import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

import {
  Heading,
  NotFoundMessage,
  UnauthorisedMessage,
} from "@peracto/peracto-ui";
import { useConfig } from "@peracto/peracto-config";

import {
  GET_ONE,
  UPDATE,
  useClient,
  getSchemaFromResource,
} from "@peracto/client";

import { MODE_EDIT } from "../RedirectsForm";

const RedirectsEditContainer = ({ children }) => {
  return (
    <div className="form-container">
      <Heading name="Edit Redirect">
        <div className="flex-grow-1 d-flex align-items-center justify-content-end">
          <Link
            className="btn btn-outline-primary"
            data-testid="back-to-redirects"
            to="/redirects"
          >
            <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
            Back to Redirects
          </Link>
        </div>
      </Heading>
      {children}
    </div>
  );
};

const RedirectsEdit = ({ RedirectsForm, match: { params } }) => {
  const { client, getResource } = useClient();
  const [loading, setLoading] = useState(true);
  const [unauthorised, setUnauthorised] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [redirect, setRedirect] = useState();
  const [redirectData, setRedirectData] = useState();

  const config = useConfig();
  const formatDate = config.get("user_format_date", config.get("format_date"));

  const fetchRedirect = async () => {
    try {
      const { data, response } = await client(GET_ONE, "redirects", {
        id: `/redirects/${params.redirect}`,
      });

      if (response.status === 404) {
        setRedirect("/redirects");
        setLoading(false);
        return;
      }

      const parsedData = {
        ...data,
        active: data.active.toString(),
        partialMatch: data.partialMatch.toString(),
      };

      setRedirectData(parsedData);
      setLoading(false);
    } catch (e) {
      console.error(e);

      if (e.status === 403) {
        setUnauthorised(true);
      }

      if (e.status === 404) {
        setNotFound(true);
      }

      setRedirect("/redirects");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const schema = getSchemaFromResource(getResource("redirects")).shape({
    code: yup.mixed().required(),
    active: yup.mixed().required(),
    partialMatch: yup.mixed().required(),
  });

  const onSubmit = async (data, actions) => {
    try {
      const parsedData = {
        ...data,
        active: data.active === "true",
        partialMatch: data.partialMatch === "true",
        code: parseInt(data.code),
      };

      const response = await client(UPDATE, "redirects", {
        id: `/redirects/${params.redirect}`,
        data: parsedData,
      });

      if (response.data.violations && response.data.violations.length > 0) {
        // Display errors for invalid fields
        actions.setSubmitting(false);
        response.data.violations.map((error) => {
          actions.setFieldError(error.propertyPath, error.message);
        });
      } else {
        actions.setSubmitting(false);
        toast.success("Redirect successfully updated!");
        setRedirect("/redirects");
      }
    } catch (e) {
      console.error(e);
      toast.error(
        e?.error?.body?.hasOwnProperty("hydra:description")
          ? e.error.body["hydra:description"]
          : "Whoops, there was a problem..."
      );
      if (e?.error?.body?.violations?.length > 0) {
        // Display errors for invalid fields
        e.error.body.violations.map((error) => {
          actions.setFieldError(error.propertyPath, error.message);
        });
      }
      actions.setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <RedirectsEditContainer>
        <div className="card">
          <div className="card-body">Loading...</div>
        </div>
      </RedirectsEditContainer>
    );
  }

  if (unauthorised) {
    return <UnauthorisedMessage />;
  }

  if (notFound) {
    return (
      <NotFoundMessage
        url="/redirects"
        message="The redirect you're looking for could not be found"
        buttonLabel="Go to Redirects"
      />
    );
  }

  return (
    <RedirectsEditContainer>
      {redirect ? (
        <Redirect to={redirect} />
      ) : (
        <>
          <Helmet>
            <title>{redirectData.fromUrl || "Redirect"} | Edit | Peracto</title>
          </Helmet>
          <RedirectsForm
            values={redirectData}
            onSubmit={onSubmit}
            schema={schema}
            mode={MODE_EDIT}
            infoPanel={
              <>
                <p data-testid="created">
                  Created:{" "}
                  {redirectData.created
                    ? formatDate(new Date(redirectData.created))
                    : "n/a"}
                </p>
                <p data-testid="modified">
                  Modified:{" "}
                  {redirectData.modified
                    ? formatDate(new Date(redirectData.modified))
                    : "n/a"}
                </p>
                <hr />
                <p data-testid="totalHits">
                  Total hits: {redirectData.hits ? redirectData.hits : 0}
                </p>
                <p data-testid="lastHit">
                  Last hit:{" "}
                  {redirectData.lastHit
                    ? formatDate(new Date(redirectData.lastHit))
                    : "n/a"}
                </p>
              </>
            }
          />
        </>
      )}
    </RedirectsEditContainer>
  );
};

export default RedirectsEdit;
