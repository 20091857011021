import { faTools } from "@fortawesome/pro-regular-svg-icons/faTools";
import { faList } from "@fortawesome/pro-regular-svg-icons/faList";
import { faMapPin } from "@fortawesome/pro-regular-svg-icons/faMapPin";
import { faGarageCar } from "@fortawesome/pro-regular-svg-icons/faGarageCar";
import { faShoppingBasket } from "@fortawesome/pro-regular-svg-icons/faShoppingBasket";
import { faTachometerAltSlow } from "@fortawesome/pro-regular-svg-icons/faTachometerAltSlow";
import { faBoxFull } from "@fortawesome/pro-regular-svg-icons/faBoxFull";
import { faTags } from "@fortawesome/pro-regular-svg-icons/faTags";
import { faBrowser } from "@fortawesome/pro-regular-svg-icons/faBrowser";
import { faUsers } from "@fortawesome/pro-regular-svg-icons/faUsers";
import { faSlidersH } from "@fortawesome/pro-regular-svg-icons/faSlidersH";

const ROLE_ADMIN = "ROLE_ADMIN";

const USER_GROUP_ADMIN = "Admin";
const USER_GROUP_BRANCH_ADMIN = "Branch Admin";
// const USER_GROUP_TRADE = "Trade";
const USER_GROUP_CUSTOMER_SERVICE = "Customer Service";
const USER_GROUP_MARKETING_EXECUTIVE = "Marketing Executive";
const USER_GROUP_MARKETING_PUBLISHER = "Marketing Publisher";

export const menu = {
  dashboard: {
    label: "Dashboard",
    icon: faTachometerAltSlow,
    path: "/",
    sortOrder: 10,
    roles: [ROLE_ADMIN],
  },
  orders: {
    label: "Orders",
    icon: faShoppingBasket,
    sortOrder: 20,
    roles: [ROLE_ADMIN],
    userGroups: [USER_GROUP_ADMIN, USER_GROUP_BRANCH_ADMIN],
    children: {
      allOrders: {
        label: "All Orders",
        path: "/orders",
        sortOrder: 10,
        roles: [ROLE_ADMIN],
        userGroups: [USER_GROUP_ADMIN, USER_GROUP_BRANCH_ADMIN],
      },
      failedOrders: {
        label: "Failed Orders",
        path: "/failed-orders",
        sortOrder: 20,
        roles: [ROLE_ADMIN],
        userGroups: [USER_GROUP_ADMIN],
      },
      orderExports: {
        label: "Order Exports",
        path: "/order-exports",
        sortOrder: 30,
        roles: [ROLE_ADMIN],
        userGroups: [USER_GROUP_ADMIN, USER_GROUP_BRANCH_ADMIN],
      },
    },
  },
  products: {
    label: "Store",
    icon: faBoxFull,
    sortOrder: 30,
    roles: [ROLE_ADMIN],
    userGroups: [
      USER_GROUP_ADMIN,
      USER_GROUP_MARKETING_EXECUTIVE,
      USER_GROUP_MARKETING_PUBLISHER,
    ],

    children: {
      // allProducts: {
      //     label: "All Products",
      //     path: "/products",
      //     sortOrder: 10,
      //     roles: [ROLE_ADMIN],
      // },

      categories: {
        label: "Categories",
        path: "/categories",
        sortOrder: 30,
        roles: [ROLE_ADMIN],
        userGroups: [USER_GROUP_ADMIN],
      },
      brands: {
        label: "Brands",
        sortOrder: 35,
        roles: [ROLE_ADMIN],
        path: "/brands",
        userGroups: [
          USER_GROUP_ADMIN,
          USER_GROUP_MARKETING_EXECUTIVE,
          USER_GROUP_MARKETING_PUBLISHER,
        ],
      },
      attributes: {
        label: "Attributes",
        sortOrder: 40,
        roles: [ROLE_ADMIN],
        userGroups: [USER_GROUP_ADMIN],

        children: {
          allAttributes: {
            label: "All Attributes",
            path: "/attributes",
            sortOrder: 10,
            roles: [ROLE_ADMIN],
          },
          attributeGroups: {
            label: "Attribute Groups",
            path: "/attribute-groups",
            sortOrder: 20,
            roles: [ROLE_ADMIN],
          },
          attributeSets: {
            label: "Attribute Sets",
            path: "/attribute-sets",
            sortOrder: 30,
            roles: [ROLE_ADMIN],
          },
        },
      },
    },
  },
  promotions: {
    label: "Promotions",
    icon: faTags,
    path: "/promotions",
    sortOrder: 40,
    roles: [ROLE_ADMIN],
    userGroups: [
      USER_GROUP_ADMIN,
      USER_GROUP_MARKETING_EXECUTIVE,
      USER_GROUP_MARKETING_PUBLISHER,
    ],
    children: {
      promotions: {
        label: "All Promotions",
        path: "/promotions",
        sortOrder: 10,
        roles: [ROLE_ADMIN],
        userGroups: [
          USER_GROUP_ADMIN,
          USER_GROUP_MARKETING_EXECUTIVE,
          USER_GROUP_MARKETING_PUBLISHER,
        ],
      },
      promotionMetrics: {
        label: "Promotion Metrics",
        path: "/promotion-metrics",
        sortOrder: 20,
        roles: [ROLE_ADMIN],
        // userGroups: [
        //     USER_GROUP_ADMIN,
        //     USER_GROUP_MARKETING_EXECUTIVE,
        //     USER_GROUP_MARKETING_PUBLISHER,
        // ],
      },
    },
  },
  content: {
    label: "Content",
    icon: faBrowser,
    sortOrder: 50,
    roles: [ROLE_ADMIN],
    userGroups: [
      USER_GROUP_ADMIN,
      USER_GROUP_MARKETING_EXECUTIVE,
      USER_GROUP_MARKETING_PUBLISHER,
      USER_GROUP_CUSTOMER_SERVICE,
      USER_GROUP_BRANCH_ADMIN,
    ],

    children: {
      pages: {
        label: "Pages",
        path: "/pages",
        sortOrder: 10,
        roles: [ROLE_ADMIN],
      },
      articles: {
        label: "Articles",
        path: "/articles",
        sortOrder: 20,
        roles: [ROLE_ADMIN],
      },
      articleCategories: {
        label: "Article Categories",
        path: "/article-categories",
        sortOrder: 30,
        roles: [ROLE_ADMIN],
      },
      templates: {
        label: "Templates",
        path: "/templates",
        sortOrder: 40,
        roles: [ROLE_ADMIN],
      },
      elements: {
        label: "Elements",
        path: "/elements",
        sortOrder: 50,
        roles: [ROLE_ADMIN],
      },
      elementAreas: {
        label: "Element Areas",
        path: "/element-areas",
        sortOrder: 60,
        roles: [ROLE_ADMIN],
      },
      forms: {
        label: "Forms",
        icon: "map-pin",
        sortOrder: 70,
        roles: [ROLE_ADMIN],
        children: {
          allForms: {
            label: "All Forms",
            path: "/forms",
            sortOrder: 10,
            roles: [ROLE_ADMIN],
            userGroups: [
              USER_GROUP_ADMIN,
              USER_GROUP_MARKETING_EXECUTIVE,
              USER_GROUP_MARKETING_PUBLISHER,
              USER_GROUP_CUSTOMER_SERVICE,
              USER_GROUP_BRANCH_ADMIN,
            ],
          },
          formFields: {
            label: "Form Fields",
            path: "/form-fields",
            sortOrder: 20,
            roles: [ROLE_ADMIN],
            userGroups: [
              USER_GROUP_ADMIN,
              USER_GROUP_MARKETING_EXECUTIVE,
              USER_GROUP_MARKETING_PUBLISHER,
              USER_GROUP_CUSTOMER_SERVICE,
              USER_GROUP_BRANCH_ADMIN,
            ],
          },
          // formSubmissions: {
          //   label: "Form Submissions",
          //   path: "/form-submissions",
          //   sortOrder: 30,
          //   roles: [ROLE_ADMIN],
          // },
        },
      },
    },
  },
  locations: {
    label: "Locations",
    icon: faMapPin,
    sortOrder: 60,
    roles: [ROLE_ADMIN],
    userGroups: [
      USER_GROUP_ADMIN,
      USER_GROUP_BRANCH_ADMIN,
      USER_GROUP_CUSTOMER_SERVICE,
    ],
    children: {
      locations: {
        label: "All Locations",
        path: "/locations",
        sortOrder: 10,
        roles: [ROLE_ADMIN],
      },
      branchReassignment: {
        label: "Temporary Branch Reassignment",
        path: "/branch-reassignment",
        sortOrder: 20,
        roles: [ROLE_ADMIN],
      },
    },
  },
  users: {
    label: "Users",
    icon: faUsers,
    sortOrder: 70,
    roles: [ROLE_ADMIN],
    userGroups: [
      USER_GROUP_ADMIN,
      USER_GROUP_BRANCH_ADMIN,
      USER_GROUP_CUSTOMER_SERVICE,
    ],

    children: {
      allUsers: {
        label: "All Users",
        path: "/users",
        sortOrder: 10,
        roles: [ROLE_ADMIN],
      },
      applications: {
        label: "User Applications",
        path: "/user-applications",
        sortOrder: 15,
        roles: [ROLE_ADMIN],
      },
      userGroups: {
        label: "User Groups",
        path: "/user-groups",
        sortOrder: 20,
        roles: [ROLE_ADMIN],
        feature: "user.userGroups",
      },
      exports: {
        label: "User Exports",
        path: "/user-exports",
        sortOrder: 25,
        roles: [ROLE_ADMIN],
        userGroups: [
          USER_GROUP_ADMIN,
          USER_GROUP_CUSTOMER_SERVICE,
          USER_GROUP_BRANCH_ADMIN,
        ],
      },
      applictionExports: {
        label: "Application Exports",
        path: "/application-exports",
        sortOrder: 30,
        roles: [ROLE_ADMIN],
        userGroups: [
          USER_GROUP_ADMIN,
          USER_GROUP_BRANCH_ADMIN,
          USER_GROUP_CUSTOMER_SERVICE,
        ],
      },
    },
  },
  configuration: {
    label: "Configuration",
    icon: faSlidersH,
    sortOrder: 80,
    roles: [ROLE_ADMIN],
    userGroups: [USER_GROUP_ADMIN],
    children: {
      settings: {
        label: "Settings",
        path: "/settings",
        sortOrder: 10,
        roles: [ROLE_ADMIN],
      },
      navigation: {
        label: "Navigation",
        path: "/menus",
        sortOrder: 20,
        roles: [ROLE_ADMIN],
      },
      redirects: {
        label: "Redirects",
        path: "/redirects",
        sortOrder: 30,
        roles: [ROLE_ADMIN],
      },
      shippingServices: {
        label: "Shipping Services",
        path: "/shipping-services",
        sortOrder: 40,
        roles: [ROLE_ADMIN],
      },
      tasks: {
        label: "Tasks",
        path: "/tasks",
        sortOrder: 50,
        roles: [ROLE_ADMIN],
      },
      countries: {
        label: "Countries",
        path: "/countries",
        sortOrder: 60,
        roles: [ROLE_ADMIN],
      },
    },
  },
  babbageAccounts: {
    label: "Babbage Accounts",
    icon: faList,
    sortOrder: 71,
    roles: [ROLE_ADMIN],
    userGroups: [USER_GROUP_ADMIN],
    children: {
      accounts: {
        label: "Accounts",
        path: "/babbage-accounts",
        sortOrder: 10,
        roles: [ROLE_ADMIN],
      },
    },
  },
  webServices: {
    label: "Web Services",
    icon: faTools,
    sortOrder: 72,
    roles: [ROLE_ADMIN],
    userGroups: [USER_GROUP_ADMIN],
    children: {
      raindata: {
        label: "Raindata",
        sortOrder: 10,
        roles: [ROLE_ADMIN],
        children: {
          vrmLookup: {
            label: "VRM Lookup (Inc. MOT History)",
            path: "/web-services/vrm",
            sortOrder: 10,
            roles: [ROLE_ADMIN],
          },
          vinLookup: {
            label: "VIN Lookup (Inc. MOT History)",
            path: "/web-services/vin",
            sortOrder: 20,
            roles: [ROLE_ADMIN],
          },
          // manualLookup: {
          //     label: "VRM Lookup",
          //     path: "/web-services/manual",
          //     sortOrder: 30,
          //     roles: [ROLE_ADMIN],
          // },
          componentsByVehicleAttributes: {
            label: "Components by Vehicle Attributes",
            path: "/web-services/component-tree",
            sortOrder: 40,
            roles: [ROLE_ADMIN],
          },
          // search: {
          //     label: "Search",
          //     path: "/web-services/search",
          //     sortOrder: 50,
          //     roles: [ROLE_ADMIN],
          // },
          searchByComponentTree: {
            label: "Search by Component Tree",
            path: "/web-services/search-by-component-tree",
            sortOrder: 60,
            roles: [ROLE_ADMIN],
          },
          applicationGuide: {
            label: "Application Guide",
            path: "/web-services/application-guide",
            sortOrder: 70,
            roles: [ROLE_ADMIN],
          },
          partDetails: {
            label: "Part Details",
            path: "/web-services/part-details",
            sortOrder: 80,
            roles: [ROLE_ADMIN],
          },
          exhaustLookup: {
            label: "Exhaust Lookup",
            path: "/web-services/exhaust",
            sortOrder: 90,
            roles: [ROLE_ADMIN],
          },
          xrefLookup: {
            label: "Xref Lookup",
            path: "/web-services/xref",
            sortOrder: 100,
            roles: [ROLE_ADMIN],
          },
        },
      },
      babbageSoap: {
        label: "Babbage Soap",
        sortOrder: 20,
        roles: [ROLE_ADMIN],
        children: {
          getPartsDetail: {
            label: "GetPartsDetail",
            path: "/web-services/get-parts-detail",
            sortOrder: 10,
            roles: [ROLE_ADMIN],
          },
          getDocument: {
            label: "GetDocument",
            path: "/web-services/get-document",
            sortOrder: 20,
            roles: [ROLE_ADMIN],
          },
          getInvoices: {
            label: "GetInvoices",
            path: "/web-services/get-invoices",
            sortOrder: 30,
            roles: [ROLE_ADMIN],
          },
          // getDocument: {
          //     label: "GetDocument",
          //     path: "/web-services/get-document",
          //     sortOrder: 40,
          //     roles: [ROLE_ADMIN],
          // },
          // getSession: {
          //     label: "GetSession",
          //     path: "/web-services/get-session",
          //     sortOrder: 50,
          //     roles: [ROLE_ADMIN],
          // },
          // putSession: {
          //     label: "PutSession",
          //     path: "/web-services/put-session",
          //     sortOrder: 60,
          //     roles: [ROLE_ADMIN],
          // },
          // endSession: {
          //     label: "EndSession",
          //     path: "/web-services/end-session",
          //     sortOrder: 70,
          //     roles: [ROLE_ADMIN],
          // },
        },
      },
      haynesPro: {
        label: "Haynes Pro",
        sortOrder: 30,
        roles: [ROLE_ADMIN],
        children: {
          getAuthenticationVrid: {
            label: "getAuthenticationVrid",
            path: "/web-services/get-haynes-auth",
            sortOrder: 10,
            roles: [ROLE_ADMIN],
          },
          getIdentificationByTecdocNumberVRM: {
            label: "getIdentificationByTecdocNumber - VRM",
            path: "/web-services/get-haynes-tech-doc-vrm",
            sortOrder: 20,
            roles: [ROLE_ADMIN],
          },
          getIdentificationByTecdocNumberVIN: {
            label: "getIdentificationByTecdocNumber - VIN",
            path: "/web-services/get-haynes-tech-doc-vin",
            sortOrder: 30,
            roles: [ROLE_ADMIN],
          },
          getIdentificationByTecdocNumberMVL: {
            label: "getIdentificationByTecdocNumber - MVL",
            path: "/web-services/get-haynes-tech-doc-mvl",
            sortOrder: 40,
            roles: [ROLE_ADMIN],
          },
          // getIdentificationByTecdocNumberManual: {
          //     label: "getIdentificationByTecdocNumber() - Manual",
          //     path: "/web-services/get-haynes-tech-doc-manual",
          //     sortOrder: 40,
          //     roles: [ROLE_ADMIN],
          // },
          getRepairtimeTypesV2: {
            label: "getRepairtimeTypesV2",
            path: "/web-services/haynes-get-repair-time-types-v2",
            sortOrder: 50,
            roles: [ROLE_ADMIN],
          },
          getRepairTimeInfosV3: {
            label: "getRepairTimeInfosV3",
            path: "/web-services/haynes-get-repair-time-infos-v3",
            sortOrder: 55,
            roles: [ROLE_ADMIN],
          },
          getRepairtimeSubnodesByGroupV3: {
            label: "getRepairtimeSubnodesByGroupV3",
            path: "/web-services/haynes-get-repair-time-subnodes-by-group-v3",
            sortOrder: 60,
            roles: [ROLE_ADMIN],
          },
          getRepairTimesSubnodeTextSearchV4: {
            label: "getRepairTimesSubnodeTextSearchV4",
            path: "/web-services/haynes-get-repair-time-subnode-text-search-v4",
            sortOrder: 65,
            roles: [ROLE_ADMIN],
          },
          getMaintenanceSystemsV6: {
            label: "getMaintenanceSystemsV6",
            path: "/web-services/haynes-pro-get-maintenance-systems-v6",
            sortOrder: 70,
            roles: [ROLE_ADMIN],
          },
          getMaintenanceTasksV7: {
            label: "getMaintenanceTasksV7",
            path: "/web-services/haynes-pro-get-maintenance-tasks-v7",
            sortOrder: 80,
            roles: [ROLE_ADMIN],
          },
        },
      },
      wismo: {
        label: "Wismo",
        sortOrder: 40,
        roles: [ROLE_ADMIN],
        children: {
          detailsRequest: {
            label: "Wismo Account Details Request",
            path: "/web-services/wismo/details-request",
            sortOrder: 10,
            roles: [ROLE_ADMIN],
          },
          orderRequest: {
            label: "Wismo Order Details Request",
            path: "/web-services/wismo/order-request",
            sortOrder: 20,
            roles: [ROLE_ADMIN],
          },
        },
      },
      webServiceLogs: {
        label: "Web Service Logs",
        sortOrder: 50,
        roles: [ROLE_ADMIN],
        path: "/web-services/web-service-logs",
      },
    },
  },

  fifm: {
    label: "Fit It For Me",
    icon: faGarageCar,
    sortOrder: 71,
    roles: [ROLE_ADMIN],
    userGroups: [USER_GROUP_ADMIN],
    children: {
      accounts: {
        label: "Applications",
        path: "/fifm-applications",
        sortOrder: 10,
        roles: [ROLE_ADMIN],
      },
    },
  },
};
