import React from "react";
import { Route, Switch } from "react-router-dom";
import { ApplicationExportList } from "./application-exports";
import { OrderExportList } from "./order-exports";
import { UserExportList } from "./user-exports";

export default () => {
    return {
        routes: [
            {
                path: "/user-exports",
                render: ({ match }) => (
                    <Switch>
                        <Route
                            path={`${match.url}`}
                            exact
                            component={UserExportList}
                        />
                    </Switch>
                ),
            },
            {
                path: "/order-exports",
                render: ({ match }) => (
                    <Switch>
                        <Route
                            path={`${match.url}`}
                            exact
                            component={OrderExportList}
                        />
                    </Switch>
                ),
            },
            {
                path: "/application-exports",
                render: ({ match }) => (
                    <Switch>
                        <Route
                            path={`${match.url}`}
                            exact
                            component={ApplicationExportList}
                        />
                    </Switch>
                ),
            },
        ],
    };
};
