/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";

import { toast } from "react-toastify";
import * as yup from "yup";
import { Helmet } from "react-helmet";

import {
  Heading,
  NotFoundMessage,
  UnauthorisedMessage,
} from "@peracto/peracto-ui";
import {
  GET_ONE,
  UPDATE,
  useClient,
  getSchemaFromResource,
} from "@peracto/client";

const FormFieldsEditContainer = ({ children }) => {
  return (
    <div className="form-container">
      <Heading name="Edit Form Fields">
        <div className="flex-grow-1 d-flex align-items-center justify-content-end">
          <Link
            className="btn btn-outline-primary"
            to="/form-fields"
            data-testid="back-to-form-fields"
          >
            <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
            Back to Form Fields
          </Link>
        </div>
      </Heading>
      {children}
    </div>
  );
};

const FormFieldsEdit = ({ FormFieldsForm, location: { pathname } }) => {
  const { client, getResource } = useClient();
  const [loading, setLoading] = useState(true);
  const [unauthorised, setUnauthorised] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [redirect, setRedirect] = useState();

  const [formFields, setFormFields] = useState();

  const fetchFormFields = async () => {
    try {
      const { data, response } = await client(GET_ONE, "form-fields", {
        id: pathname,
      });

      setFormFields(data);
      setLoading(false);

      if (response.status === 404) {
        setRedirect("/form-fields");
      }
    } catch (e) {
      console.error(e);

      if (e.status === 403) {
        setUnauthorised(true);
      }

      if (e.status === 404) {
        setNotFound(true);
      }

      setLoading(false);
      setRedirect("/form-fields");
    }
  };

  useEffect(() => {
    fetchFormFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const schema = getSchemaFromResource(getResource("form-fields")).shape({
    defaultValue: yup.string().nullable(),
    helpText: yup.string().nullable(),
    thirdPartyFieldId: yup.string().nullable(),
    uniqueString: yup.string().nullable(),
  });

  const onSubmit = async (data, actions) => {
    try {
      const response = await client(UPDATE, "form-fields", {
        id: pathname,
        data,
      });

      if (response.data.violations && response.data.violations.length > 0) {
        // Display errors for invalid fields
        actions.setSubmitting(false);
        response.data.violations.map((error) => {
          actions.setFieldError(error.propertyPath, error.message);
        });
      } else {
        setRedirect(response.data.id);
        actions.setSubmitting(false);
        actions.resetForm({ values: data });
        toast.success("Form Field successfully updated!");
      }
    } catch (e) {
      console.error(e);
      toast.error(
        e?.error?.body?.hasOwnProperty("hydra:description")
          ? e.error.body["hydra:description"]
          : "Whoops, there was a problem..."
      );
      if (e?.error?.body?.violations?.length > 0) {
        // Display errors for invalid fields
        e.error.body.violations.map((error) => {
          actions.setFieldError(error.propertyPath, error.message);
        });
      }
      actions.setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <FormFieldsEditContainer>
        <div className="card">
          <div className="card-body">Loading...</div>
        </div>
      </FormFieldsEditContainer>
    );
  }

  if (unauthorised) {
    return <UnauthorisedMessage />;
  }

  if (notFound) {
    return (
      <NotFoundMessage
        url="/form-fields"
        message="The form field you're looking for could not be found"
        buttonLabel="Go to Form Fields"
      />
    );
  }

  return (
    <FormFieldsEditContainer>
      {redirect && <Redirect to={redirect} />}

      <Helmet>
        <title>{formFields.label || "Form Field"} | Edit | Peracto</title>
      </Helmet>

      <FormFieldsForm values={formFields} onSubmit={onSubmit} schema={schema} />
    </FormFieldsEditContainer>
  );
};

export default FormFieldsEdit;
