/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/pro-regular-svg-icons/faPlay";

import {
  Form,
  Group,
  Input,
  Select,
  Textarea,
  FormActions,
} from "@peracto/peracto-ui";
import { useConfig } from "@peracto/peracto-config";
import { onRunTask } from "../util";

export const MODE_EDIT = "edit";
export const MODE_ADD = "add";

const statusOptions = [
  { label: "Active", value: "true" },
  { label: "Inactive", value: "false" },
];

const defaultScheduleValue = {
  label: "Every",
  value: "*",
};

const generateScheduleOptions = ({
  max = 1,
  min = 0,
  selectInterval = false,
  multipleOf,
  labels,
}) => {
  const values = [defaultScheduleValue];

  for (let i = min; i <= max; i++) {
    if (!multipleOf || i % multipleOf === 0) {
      if (selectInterval && i > 0) {
        values.push({
          label: `at ${i}`,
          value: `${i}`,
        });

        values.push({
          label: `every ${i}`,
          value: `*/${i}`,
        });
      } else {
        values.push({
          label: Array.isArray(labels) ? labels[i - min] : i,
          value: `${i}`,
        });
      }
    }
  }
  return values;
};

const TasksForm = ({ values, mode, ...props }) => {
  const config = useConfig();
  const API_URL = config.get("api");
  const { tasks } = config.get("features", {});
  const hideScheduleTimes = tasks?.hideScheduleTimes;

  return (
    <>
      <FormActions>
        <a
          className="px-0 btn btn-link"
          onClick={() => onRunTask(API_URL, values.code)}
        >
          <FontAwesomeIcon icon={faPlay} className="mr-2" />
          Run Task Manually
        </a>
      </FormActions>
      <Form
        autoComplete="off"
        values={{
          ...values,
          active: values.active || "false",
          schedule: {
            ...values.schedule,
            days: values.schedule?.days || (mode === MODE_ADD ? "1" : null),
            hours: values.schedule?.hours || (mode === MODE_ADD ? "2" : null),
            minutes:
              values.schedule?.minutes || (mode === MODE_ADD ? "30" : null),
          },
        }}
        {...props}
      >
        <Group key="core" id="core" name="Core">
          <Input name="name" label="Name" readOnly disabled />
          <Textarea name="description" label="Description" readOnly disabled />
        </Group>
        <Group key="schedule" id="schedule" name="Schedule">
          <div className="row">
            <div className="col-12">
              <Select
                name="active"
                label="Schedule Status"
                placeholder="Please select..."
                help="When Active the task will run according to the configuration set. Tasks should only be run as frequently as is strictly necessary."
                options={statusOptions}
              />
            </div>

            {!hideScheduleTimes && (
              <>
                <div className="col">
                  <Select
                    name="schedule.months"
                    label="Month"
                    placeholder="Please select..."
                    options={generateScheduleOptions({
                      max: 12,
                      min: 1,
                      labels: [
                        "January",
                        "February",
                        "March",
                        "April",
                        "May",
                        "June",
                        "July",
                        "August",
                        "September",
                        "October",
                        "November",
                        "December",
                      ],
                    })}
                    defaultValue={{
                      label: "Select time...",
                      value: null,
                    }}
                  />
                </div>

                <div className="col">
                  <Select
                    name="schedule.days"
                    label="Day"
                    placeholder="Please select..."
                    options={generateScheduleOptions({
                      max: 31,
                      min: 1,
                    })}
                    defaultValue={{
                      label: "Select time...",
                      value: null,
                    }}
                  />
                </div>

                <div className="col">
                  <Select
                    name="schedule.hours"
                    label="Hour"
                    placeholder="Please select..."
                    options={generateScheduleOptions({
                      max: 23,
                      selectInterval: true,
                    })}
                    defaultValue={{
                      label: "Select time...",
                      value: null,
                    }}
                  />
                </div>

                <div className="col">
                  <Select
                    name="schedule.minutes"
                    label="Minute"
                    placeholder="Please select..."
                    options={generateScheduleOptions({
                      max: 59,
                      selectInterval: true,
                      multipleOf: 5,
                    })}
                    defaultValue={{
                      label: "Select time...",
                      value: null,
                    }}
                  />
                </div>

                <div className="col">
                  <Select
                    name="schedule.weekdays"
                    label="Weekday"
                    placeholder="Please select..."
                    options={generateScheduleOptions({
                      max: 6,
                      labels: [
                        "Sunday",
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday",
                        "Saturday",
                      ],
                    })}
                    defaultValue={{
                      label: "Select time...",
                      value: null,
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </Group>
      </Form>
    </>
  );
};

TasksForm.displayName = "TasksForm";

TasksForm.propTypes = {
  values: PropTypes.object,
  schema: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default TasksForm;
