import React, { useState } from "react";

import { useLocation } from "react-router-dom";
import { Heading } from "@peracto/peracto-ui";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";
import { FormExportList } from "./components/FormExportList";
import { FormExportForm } from "./components/FormExportForm";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons/faFileExport";
import { Helmet } from "react-helmet";

const FormsExport = (props) => {
  const label = props.match.params.label;

  const location = useLocation();
  const id = location.state?.id || null;
  const [showFormExportForm, setShowFormExportForm] = useState(false);

  return (
    <>
      <Helmet>
        <title>Form Exports | Peracto</title>
      </Helmet>
      <div data-testid="form-exports">
        <Heading name={`Form Exports (${label.replace(/\+/g, " ")})`}>
          <div className="flex-grow-1 d-flex align-items-center justify-content-end gap-x-2">
            {!showFormExportForm ? (
              <>
                <button
                  className="btn btn-primary"
                  data-testid="export-user"
                  onClick={() => setShowFormExportForm(true)}
                >
                  <FontAwesomeIcon icon={faFileExport} className="mr-2" />
                  Generate New Export
                </button>
                <Link className="btn btn-outline-primary" to="/forms">
                  <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
                  Back to Forms
                </Link>
              </>
            ) : (
              <>
                <button
                  className="btn btn-outline-primary"
                  onClick={() => setShowFormExportForm(false)}
                >
                  <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
                  Back to Exports
                </button>
              </>
            )}
          </div>
        </Heading>
        {showFormExportForm ? (
          <FormExportForm
            formId={id}
            showFormExportForm={showFormExportForm}
            setShowFormExportForm={setShowFormExportForm}
          />
        ) : (
          <FormExportList
            formId={id}
            showFormExportForm={showFormExportForm}
            setShowFormExportForm={setShowFormExportForm}
          />
        )}
      </div>
    </>
  );
};

export default FormsExport;
