import styled from 'styled-components'

export const NavigationTree = styled.div`
    .rst__nodeContent {
        right: 0;
    }

    .rst__rowToolbar,
    .rst__rowLabel {
        flex: 1;
        display: flex;
        align-items: center;
    }

    .rst__rowLabel {
        justify-content: space-between;
    }

    .rst__rowToolbar {
        justify-content: flex-end;
    }

    .rst__rowSubtitle {
        font-size: 85%;
        line-height: inherit;
        font-weight: normal;
        white-space: pre;
    }

    .rst__moveHandle {
        min-width: 44px;
    }
`
