/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo } from "react";
import { HydraTable, Modal } from "@peracto/peracto-ui";

import { GET_LIST, useClient } from "@peracto/client";

const RedirectsImportStatusList = ({ data, filter, showAll }) => {
  const { client } = useClient();
  const [dataModal, setDataModal] = useState();

  const errorColumns = [
    {
      Header: "Error Message",
      accessor: "errors",
      id: "errorMessage",
      Cell: ({ cell: { value } }) =>
        value?.length > 0 ? value[0].message : "-",
    },
    {
      Header: "Error Data",
      accessor: "errors",
      id: "errorData",
      Cell: ({ cell: { value } }) =>
        value?.length > 0 ? (
          value[0].extra_data?.errors ? (
            value[0].extra_data?.errors?.map((error) => (
              <p className="mb-0">
                {error.property}: {error.message}
              </p>
            ))
          ) : value[0].message ? (
            <p className="mb-0">{value[0].message}</p>
          ) : (
            "-"
          )
        ) : (
          "-"
        ),
    },
  ];

  const successColumns = [
    {
      accessor: "data",
      Cell: ({ cell: { value } }) => (
        <button
          className="px-0 btn btn-link"
          onClick={() => setDataModal(value)}
        >
          Show Data
        </button>
      ),
    },
  ];

  const additionalColumns = filter.successful ? successColumns : errorColumns;

  const columns = useMemo(
    () => [
      {
        accessor: "data",
        id: "primaryColumn",
        Cell: ({ cell: { value } }) => {
          return value?.fromUrl || "-";
        },
      },
      ...additionalColumns,
    ],
    [showAll]
  );

  const onFetchData = (options) =>
    client(GET_LIST, `import-lines`, {
      ...options,
      filter,
    });

  return useMemo(
    () => (
      <>
        <HydraTable
          columns={columns}
          onFetchData={onFetchData}
          noResultsMessage="No errors!"
          initialData={data}
        />

        <Modal
          isVisible={!!dataModal}
          title="Showing Row Data"
          close={() => setDataModal(null)}
          buttons={[
            {
              type: "btn-outline-secondary",
              text: "Close",
              action: () => {
                setDataModal(null);
              },
            },
          ]}
        >
          {!!dataModal && (
            <table className="table text-left table-sm">
              {Object.entries(dataModal).map((entry, idx) => {
                return (
                  <tr>
                    <td className={idx === 0 ? "border-top-0" : ""}>
                      <span className="font-weight-bold">{entry[0]}:</span>
                    </td>
                    <td className={idx === 0 ? "border-top-0" : ""}>
                      <span style={{ wordBreak: "break-all" }}>{entry[1]}</span>
                    </td>
                  </tr>
                );
              })}
            </table>
          )}
        </Modal>
      </>
    ),
    [showAll, dataModal]
  );
};

export default RedirectsImportStatusList;
