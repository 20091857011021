import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";

import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

import {
    Heading,
    NotFoundMessage,
    UnauthorisedMessage,
} from "@peracto/peracto-ui";

import {
    GET_ONE,
    UPDATE,
    useClient,
    getSchemaFromResource,
} from "@peracto/client";
import { formatDataForAPI } from "../util";
import { SUSPENSION_REASONS } from "../UserForm/SuspendUser";
import { LKQ_REQUIRED_USER_ID_GROUPS } from "../UserForm";

import last from "lodash/last";

const UserEditContainer = ({ children }) => {
    return (
        <div className="form-container">
            <Heading name="Edit User">
                <div className="flex-grow-1 d-flex align-items-center justify-content-end">
                    <Link className="btn btn-outline-primary" to="/users">
                        <FontAwesomeIcon
                            icon={faArrowCircleLeft}
                            className="mr-2"
                        />
                        Back to Users
                    </Link>
                </div>
            </Heading>

            {children}
        </div>
    );
};

export const UserEdit = ({ UserForm, location: { pathname } }) => {
    const { client, getResource } = useClient();
    const [loading, setLoading] = useState(true);
    const [unauthorised, setUnauthorised] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [redirect, setRedirect] = useState();

    const [formData, setFormData] = useState();

    const fetchUser = async () => {
        try {
            const { data: userData, response: userResponse } = await client(
                GET_ONE,
                "users",
                {
                    id: pathname,
                }
            );

            const customerGroup = userData.customerGroup
                ? userData.customerGroup["@id"]
                : null;
            const defaultBranch = userData?.defaultBranch
                ? userData?.defaultBranch?.["@id"]
                : null;

            const splitDateOfBirth = userData?.dateOfBirth?.split("-");

            let createdByName = "";

            if (
                userData?.createdBy?.firstName ||
                userData?.createdBy?.lastName
            ) {
                createdByName = `${userData?.createdBy?.firstName || ""} ${
                    userData?.createdBy?.lastName || ""
                }`;
            }

            const suspendedLogs = userData?.userStatusLogs?.filter(
                (log) => log?.type === "suspended"
            );

            let userSuspensionReason = last(suspendedLogs)?.reason;

            const status_reason = !SUSPENSION_REASONS.includes(
                userSuspensionReason
            )
                ? !userSuspensionReason
                    ? ""
                    : "Other"
                : userSuspensionReason;

            const status_reason_other = !SUSPENSION_REASONS.includes(
                userSuspensionReason
            )
                ? userSuspensionReason
                : "";

            setFormData({
                ...userData,
                lkqUserId: userData.lkqUserId || "",
                customerGroup,
                defaultBranch,
                splitDateOfBirth: {
                    day: splitDateOfBirth?.[2] || "",
                    month: splitDateOfBirth?.[1] || "",
                    year: splitDateOfBirth?.[0] || "",
                },
                status: userData?.enabled ? "active" : "suspended",
                createdByName,
                status_reason,
                status_reason_other,
            });

            if (userResponse.status === 404) {
                setRedirect("/users");
            }
        } catch (e) {
            console.error(e);

            if (e.status === 403) {
                setUnauthorised(true);
            }

            if (e.status === 404) {
                setNotFound(true);
            }

            setRedirect("/users");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const schema = getSchemaFromResource(getResource("users")).shape({
        email: yup.string().required("Email is required."),
        tradingCountry: yup.string().required("Trading Country is required."),
        title: yup.string().nullable(),
        telephone: yup.string().nullable(),
        status_reason: yup.string().when("status", (status, schema) => {
            return status === "suspended"
                ? schema.required()
                : schema.notRequired();
        }),
        status_reason_other: yup
            .string()
            .when("status_reason", (statusReason, schema) => {
                return statusReason === "Other"
                    ? schema.required()
                    : schema.notRequired();
            }),
        lkqUserId: yup.string().when("userGroup", (userGroup, schema) => {
            return LKQ_REQUIRED_USER_ID_GROUPS.includes(userGroup)
                ? schema.required()
                : schema.notRequired();
        }),
        babbageAccounts: yup
            .array()
            .min(1, "At least 1 Babbage account must be assigned to the user.")
            .required(),
    });

    const onSubmit = async (data, actions) => {
        const formattedData = formatDataForAPI(data);
        try {
            const response = await client(UPDATE, "users", {
                id: pathname,
                data: formattedData,
            });
            if (
                response.data.violations &&
                response.data.violations.length > 0
            ) {
                // Display errors for invalid fields
                actions.setSubmitting(false);
                response.data.violations.forEach((error) => {
                    actions.setFieldError(
                        `${error.propertyPath}`,
                        error.message
                    );
                });
            } else {
                setLoading(true);
                actions.setSubmitting(false);
                actions.resetForm(data);

                toast.success("User successfully updated!");
                fetchUser();
            }
        } catch (e) {
            console.error(e);
            // eslint-disable-next-line no-unused-expressions
            e?.error?.body?.violations?.forEach((error) => {
                actions.setFieldError(`${error.propertyPath}`, error.message);
            });
            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "Whoops, there was a problem..."
            );
            actions.setSubmitting(false);
        }
    };

    if (loading) {
        return (
            <UserEditContainer>
                <div className="card">
                    <div className="card-body">Loading...</div>
                </div>
            </UserEditContainer>
        );
    }

    if (unauthorised) {
        return <UnauthorisedMessage />;
    }

    if (notFound) {
        return (
            <NotFoundMessage
                url="/users"
                message="The user you're looking for could not be found"
                buttonLabel="Go to Users"
            />
        );
    }

    return (
        <UserEditContainer>
            {redirect && <Redirect to={redirect} />}

            <Helmet>
                <title>
                    {`${formData.firstName} ${formData.lastName}`} | Edit |
                    Peracto
                </title>
            </Helmet>

            <UserForm
                values={formData}
                onSubmit={onSubmit}
                schema={schema}
                setFormData={setFormData}
                testId="edit"
            />
        </UserEditContainer>
    );
};
