import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";

import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

import {
  Heading,
  NotFoundMessage,
  UnauthorisedMessage,
} from "@peracto/peracto-ui";

import {
  GET_ONE,
  UPDATE,
  useClient,
  getSchemaFromResource,
} from "@peracto/client";

import { MODE_EDIT } from "../TasksForm";

const formatDataForForm = (data) => {
  return {
    ...data,
    active: data.active.toString(),
  };
};

const TasksEditContainer = ({ children }) => {
  return (
    <div className="form-container">
      <Heading name="Edit Task">
        <div className="flex-grow-1 d-flex align-items-center justify-content-end">
          <Link className="btn btn-outline-primary" to="/tasks">
            <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
            Back to Tasks
          </Link>
        </div>
      </Heading>
      {children}
    </div>
  );
};

const TasksEdit = ({ TasksForm, location: { pathname } }) => {
  const { client, getResource } = useClient();
  const [loading, setLoading] = useState(true);
  const [unauthorised, setUnauthorised] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [redirect, setRedirect] = useState();
  const [taskData, setTaskData] = useState();

  const fetchTask = async () => {
    try {
      const { data, response } = await client(GET_ONE, "tasks", {
        id: pathname,
      });

      if (response.status === 404) {
        setRedirect("/tasks");
        setLoading(false);
        return;
      }

      const parsedData = formatDataForForm(data);

      console.log("parsedData", parsedData);
      setTaskData(parsedData);
      setLoading(false);
    } catch (e) {
      console.error(e);

      if (e.status === 403) {
        setUnauthorised(true);
      }

      if (e.status === 404) {
        setNotFound(true);
      }

      setRedirect("/tasks");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const schema = getSchemaFromResource(getResource("tasks"));

  const onSubmit = async ({ active, schedule }, actions) => {
    try {
      const response = await client(UPDATE, "tasks", {
        id: pathname,
        data: {
          active: active === "true", // cast to boolean,
          schedule: {
            minutes: schedule.minutes || "*",
            hours: schedule.hours || "*",
            months: schedule.months || "*",
            weekdays: schedule.weekdays || "*",
            days: schedule.days || "*",
          },
        },
      });

      if (response.data.violations?.length > 0) {
        // Display errors for invalid fields
        actions.setSubmitting(false);
        // eslint-disable-next-line array-callback-return
        response.data.violations.map((error) => {
          actions.setFieldError(error.propertyPath, error.message);
        });
      } else {
        actions.setSubmitting(false);
        toast.success("Task successfully updated!");
        const parsedData = formatDataForForm(response.data);

        setTaskData(parsedData);
      }
    } catch (e) {
      console.error(e);
      toast.error(
        e?.error?.body?.hasOwnProperty("hydra:description")
          ? e.error.body["hydra:description"]
          : "Whoops, there was a problem..."
      );
      actions.setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <TasksEditContainer>
        <div className="card">
          <div className="card-body">Loading...</div>
        </div>
      </TasksEditContainer>
    );
  }

  if (unauthorised) {
    return <UnauthorisedMessage />;
  }

  if (notFound) {
    return (
      <NotFoundMessage
        url="/tasks"
        message="The task you're looking for could not be found"
        buttonLabel="Go to Tasks"
      />
    );
  }

  return (
    <TasksEditContainer>
      {redirect ? (
        <Redirect to={redirect} />
      ) : (
        <>
          <Helmet>
            <title>{taskData.name || "Task"} | Edit | Peracto</title>
          </Helmet>
          <TasksForm
            values={taskData}
            onSubmit={onSubmit}
            schema={schema}
            mode={MODE_EDIT}
          />
        </>
      )}
    </TasksEditContainer>
  );
};

export default TasksEdit;
