import React, { useState, useEffect } from "react";
import AsyncSelect from "react-select/async";
import debounce from "debounce-promise";
import { Field } from "formik";
import { Form, Input, File, Select, DateRange } from "@peracto/peracto-ui";
import { useConfig } from "@peracto/peracto-config";

import { useSettings } from "@peracto/peracto-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import {
  navigationTypeOptions,
  directLinkTypeOptions,
  getEndpointsForTypes,
  getKeyForIdentifierLabel,
} from "./util";
import * as yup from "yup";

import {
  GET_LIST,
  GET_ONE,
  useClient,
  getSchemaFromResource,
} from "@peracto/client";

const NO_CONTENT = ["branch", "directLink"];
const defaultSelectValue = { label: "Please select...", value: "" };
const urlProtocolPattern = /^((http|https):\/\/)/;

const convertToURLString = (path) => {
  return (
    path
      .split(" ")
      .join("-")
      // eslint-disable-next-line no-useless-escape
      .replace(/[`~!$Â£â‚¬^*_|;@'",()<>\{\}\[\]\\]/gi, "")
      .replace(/-+/g, "-")
  );
};

const NavigationTreeForm = ({
  values = {},
  parent = null,
  onSubmitForm = () => {},
  onClose,
}) => {
  const { client, getResource } = useClient();
  const [identifierValue, setIdentifierValue] = useState();
  const [pathReadOnly, setPathReadOnly] = useState(false);
  const [initialValues, setInitialValues] = useState();

  const config = useConfig();
  const { navigation } = config.get("features", {});

  const readOnlyPaths = navigation?.readOnlyPaths || false;
  const allowImages = navigation?.images || false;

  const { values: p_values } = useSettings();
  const categoryPath =
    p_values?.sub_urls?.sitemap_categories_sub_url || "category";
  const articleCategoryPath =
    p_values?.sub_urls?.sitemap_article_categories_sub_url || "articles";
  const articlePath = p_values?.sub_urls?.sitemap_articles_sub_url || "article";

  const schema = getSchemaFromResource(getResource("menu-items")).shape({
    menuCode: yup.string().notRequired(),
    path: yup.string().required(),
    linkText: yup.string().required(),
  });

  const fetchOptions = async (input, inputType) => {
    const { endpoint, params } = getEndpointsForTypes(inputType, input);
    const itemKey = getKeyForIdentifierLabel(inputType);

    const { data } = await client(GET_LIST, endpoint, {
      ...params,
    });

    let pathValue = "";

    switch (inputType) {
      case "category":
        pathValue = "identifier";
        break;
      case "content":
      case "article":
        pathValue = "slug";
        break;
      default:
        pathValue = "path";
    }

    const values = data.map((item) => ({
      label: item[itemKey],
      value: item.originId,
      path: item[pathValue] || null,
    }));

    return values;
  };

  const debouncedFetchOptions = debounce(fetchOptions, 200);

  const setDefaultIdentifierValue = (content) => {
    const keyName = getKeyForIdentifierLabel(values.itemType);
    setIdentifierValue({
      label: content[keyName] || content["@id"],
      value: content.id,
    });
  };

  const loadContentData = async () => {
    let path = values?.path;

    if (!path || NO_CONTENT.includes(values?.itemType)) return;

    if (path[0] === "/") {
      path = path.substr(1);
    }

    const { data } = await client(GET_ONE, "menu-items-by-path", {
      id: `menu-items-by-path/${path}`,
    });

    if (data.content) {
      setDefaultIdentifierValue(data.content);
    } else {
      setIdentifierValue(defaultSelectValue);
    }
  };
  useEffect(() => {
    if (values["@id"] && values.content) {
      setDefaultIdentifierValue(values.content);
    } else {
      loadContentData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    setInitialValues(
      !!values["@id"]
        ? {
            ...values,
          }
        : {
            linkType: "external",
            identifier: "",
            linkText: "",
            image: "",
            menuCode: "",
            path: "",
            displayFrom: "",
            displayTo: "",
          }
    );

    return () => {
      setInitialValues({});
    };
  }, [setInitialValues, values]);

  return (
    <Form
      className="text-left w-100"
      values={initialValues}
      autoComplete="off"
      onSubmit={(values, actions) => {
        const parsedValues = { ...values };

        if (identifierValue?.value) {
          parsedValues.identifier = identifierValue.value;
        }

        onSubmitForm(parsedValues, actions);
      }}
      showActionPanel={false}
      schema={schema}
    >
      <Field name="itemType">
        {({ form, field }) => (
          <Select
            name={field.name}
            label="Navigation Item Type"
            placeholder="Please select..."
            options={navigationTypeOptions}
            defaultValue={defaultSelectValue}
            handleChange={() => {
              form.setFieldValue("identifier", null);
              setIdentifierValue(defaultSelectValue);
            }}
          />
        )}
      </Field>

      <Field name="identifier">
        {({ form, field }) => {
          return (
            <>
              {!NO_CONTENT.includes(form.values.itemType) && (
                <div className="form-group">
                  <label>Select Item</label>
                  <AsyncSelect
                    className="w-100"
                    classNamePrefix="list"
                    isDisabled={!form.values.itemType}
                    loadOptions={(input) =>
                      debouncedFetchOptions(input, form.values.itemType)
                    }
                    isSearchable={true}
                    defaultValue={{
                      label: "Please Select",
                      value: null,
                    }}
                    onChange={(option) => {
                      setIdentifierValue(option);
                      form.setFieldValue(field.name, option.value);

                      /* Set the Link Text to match the selected item's label by default */
                      form.setFieldValue("linkText", option.label);

                      /* Make the path field readonly if it's an article category and it already has a path */
                      setPathReadOnly(
                        form.values.itemType === "articleCategory" &&
                          !!option?.path
                      );

                      /* For article categories set the 'path' field as the one set on the article category. */
                      const label =
                        form.values.itemType === "articleCategory"
                          ? option?.path || option.label
                          : option.label;

                      /*  
                                                Set the Path based on the selected item's label by default 
                                                1. Prefix the URL with the parent's path if adding a child item
                                                2. Otherwise just use the item's label
                                            */
                      let path =
                        option?.path ||
                        (parent?.path
                          ? `${parent.path}/${label}` /* [1] */
                          : label); /* [2] */

                      form.setFieldValue(
                        "path",
                        convertToURLString(path).toLowerCase()
                      );
                    }}
                    value={identifierValue}
                    placeholder="Select an option..."
                    noOptionsMessage={({ inputValue }) => {
                      if (inputValue.length > 0) {
                        return `No items found for '${inputValue}'.`;
                      } else {
                        return "Enter text to begin searching.";
                      }
                    }}
                  />
                </div>
              )}
            </>
          );
        }}
      </Field>

      <Field name="linkType">
        {({ field, form }) => (
          <>
            {form.values.itemType === "directLink" && (
              <Select
                name={field.name}
                label="Link type"
                placeholder="Please select..."
                options={directLinkTypeOptions}
                defaultValue={defaultSelectValue}
                handleChange={(value) => {
                  const path = form?.values?.path || "";

                  if (form.values.itemType === "directLink") {
                    // If it's an external link, make sure the direct link starts with a protocol
                    if (
                      !urlProtocolPattern.test(path) &&
                      value === "external"
                    ) {
                      form.setFieldValue("path", "http://" + path);
                    }

                    // If it's an internal link, remove the protocol from the string
                    if (urlProtocolPattern.test(path) && value === "internal") {
                      form.setFieldValue(
                        "path",
                        path.replace(urlProtocolPattern, "")
                      );
                    }
                  }
                }}
              />
            )}
          </>
        )}
      </Field>

      <Field name="linkText">
        {({ field, form }) => (
          <>
            <Input
              name={field.name}
              label="Link Text"
              placeholder="Enter Link Text..."
              required
              onBlur={() => {
                if (
                  field?.value?.length > 0 &&
                  form.values.itemType === "branch" &&
                  form.values.path?.length === 0
                ) {
                  // Strip slash at start of URL
                  if (field.value[0] === "/") {
                    field.value = field.value.substr(1);
                  }

                  form.setFieldValue(
                    "path",
                    convertToURLString(`menu-branch/${field.value}`)
                  );
                }
              }}
            />
          </>
        )}
      </Field>

      <Field name="path">
        {({ field, form }) => (
          <>
            {form.values.itemType && (
              <Input
                name={field.name}
                label="Link Location"
                placeholder="Enter Link Location..."
                prefix={
                  form.values.itemType !== "directLink" ||
                  (form.values.itemType === "directLink" &&
                    form.values.linkType === "internal")
                    ? `${
                        process.env.REACT_APP_STOREFRONT_URL ||
                        window.location.origin
                      }/${
                        form.values.itemType === "category"
                          ? `${categoryPath}/`
                          : ""
                      }${
                        form.values.itemType === "articleCategory"
                          ? `${articleCategoryPath}/`
                          : ""
                      }${
                        form.values.itemType === "article"
                          ? `${articlePath}/`
                          : ""
                      }`
                    : null
                }
                onBlur={() => {
                  if (field?.value?.length > 0) {
                    // Strip slash at start of URL
                    if (field.value[0] === "/") {
                      field.value = field.value.substr(1);
                    }

                    // If it's a direct link, make sure the URL starts with a protocol
                    if (
                      form.values.itemType === "directLink" &&
                      form.values.linkType === "external"
                    ) {
                      if (!urlProtocolPattern.test(field.value)) {
                        field.value = "http://" + field.value;
                      }
                    }

                    form.setFieldValue(
                      field.name,
                      convertToURLString(field.value)
                    );
                  }
                }}
                readOnly={
                  pathReadOnly ||
                  (readOnlyPaths &&
                    !["directLink", "branch"].includes(form.values.itemType))
                }
                required
              />
            )}
          </>
        )}
      </Field>

      {allowImages && (
        <File
          label="Image"
          name={`image`}
          placeholder="Assign an image to this menu item"
          testId="navigation-form__image"
        />
      )}

      <div className="d-flex justify-content-between">
        <DateRange
          fromName="displayFrom"
          fromLabel="Display From"
          toName="displayTo"
          toLabel="Display To"
        />

        <Field name="displayFrom">
          {({ form }) => (
            <button
              className="px-0 ml-2 btn btn-link"
              onClick={(e) => {
                e.preventDefault();
                form.setFieldValue("displayFrom", null);
                form.setFieldValue("displayTo", null);
              }}
            >
              <FontAwesomeIcon icon={faTimes} className="mr-2" />
              Clear
            </button>
          )}
        </Field>
      </div>

      <div className="d-flex justify-content-between">
        <button
          className="btn btn-outline-secondary"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
        >
          Cancel
        </button>

        <button className="btn btn-success" type="submit">
          Submit
        </button>
      </div>
    </Form>
  );
};

NavigationTreeForm.displayName = "NavigationTreeForm";

export default NavigationTreeForm;
