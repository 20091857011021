import React, { useState, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-regular-svg-icons/faDownload";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons/faSpinner";

import classNames from "classnames";
import { toast } from "react-toastify";

import axios from "axios";

import { HydraTable, TimeAgo } from "@peracto/peracto-ui";

import { useConfig } from "@peracto/peracto-config";
import { GET_LIST, useClient } from "@peracto/client";

export const FormExportList = (props) => {
  const { formId } = props;
  const { client } = useClient();
  const config = useConfig();
  const API_URL = config.get("api");

  const [downloadingExport, setDownloadingExport] = useState(null);
  const formatDate = config.get("user_format_date", config.get("format_date"));

  const onDownloadFormExport = async (id) => {
    setDownloadingExport(id);

    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      const { data } = await axios.get(
        `${API_URL}/export-logs/${id}/pre-signed-url`,
        {
          headers,
        }
      );

      const s3Url = data?.preSignedUrl;

      if (s3Url) {
        window.location = s3Url;
      }
    } catch (e) {
      console.error(e);
      toast.error(
        e?.error?.body?.hasOwnProperty("hydra:description")
          ? e.error.body["hydra:description"]
          : "Whoops, there was a problem..."
      );
    } finally {
      setDownloadingExport(null);
    }
  };

  // The 'selectable' property is used to define if the
  // column is available in the 'manage columns' dropdown
  const columns = useMemo(
    () => [
      {
        Header: "Email",
        accessor: "user",
        sortType: "basic",
        disableSorting: true,

        Cell: (row) => row.cell.value?.email || "-",
        selectable: false,
      },
      {
        Header: "Created",
        accessor: "createdAt",
        disableSorting: true,

        Cell: ({ cell: { value } }) => {
          const date = new Date(value);
          const validDate = !!date.getDate() === true;

          return value && validDate ? (
            <TimeAgo title={formatDate(new Date(value))} date={value} />
          ) : (
            "-"
          );
        },
        selectable: false,
      },

      {
        Header: "Status",
        accessor: "status",
        disableSorting: true,
        selectable: false,
        Cell: (row) => {
          return (
            <span
              className={classNames(
                "badge",
                "badge-pill",
                "mr-1",
                "capitalize",
                {
                  "badge-warning": row.cell.value === "processing",
                  "badge-success": row.cell.value === "complete",
                  "badge-danger": row.cell.value === "error",
                }
              )}
            >
              {row.cell.value}
            </span>
          );
        },
      },

      {
        Header: "Row Count",
        accessor: "rowCount",
        sortType: "basic",
        disableSorting: true,

        Cell: ({ cell: { value } }) => (value ? value : "0"),
        selectable: false,
      },

      {
        Header: () => (
          <span className="inline-block w-full text-right">Download</span>
        ),
        id: "id",
        disableSorting: true,
        accessor: "firstName",
        sortType: "basic",
        Cell: (row) => {
          const id = row.row.original.originId;
          const disabled = row.row.original.status !== "complete";
          const downloadInitialised = downloadingExport === id;

          return (
            <div className="text-right">
              <button
                className="btn btn-sm btn-outline-primary"
                disabled={!!downloadingExport || disabled}
                onClick={(e) => {
                  e.preventDefault();
                  if (!disabled) {
                    onDownloadFormExport(id);
                  }
                }}
              >
                <FontAwesomeIcon
                  icon={downloadInitialised ? faSpinner : faDownload}
                  {...(downloadInitialised && {
                    spin: true,
                  })}
                  className="mr-2"
                />
                Download
              </button>
            </div>
          );
        },
        selectable: false,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [downloadingExport, formatDate]
  );

  const onFetchData = (options) => {
    return client(GET_LIST, `export-logs?options.value=${formId}`, {
      ...options,
      filter: {
        type: "form-submissions",
        ...options.filter,
      },
    });
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="card card--transparent">
            <div className="card-body">
              <HydraTable
                columns={columns}
                onFetchData={onFetchData}
                defaultFilters={{
                  createdAt: "",
                  status: "",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
