import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";

import { Helmet } from "react-helmet";
import {
  HydraTable,
  Heading,
  DateRangeFilter,
  TimeAgo,
} from "@peracto/peracto-ui";

import { useConfig } from "@peracto/peracto-config";
import { GET_LIST, useClient } from "@peracto/client";

const TaskRunsList = () => {
  // Default visible columns - key = column accessor
  const [selectedColumns, onSelectColumn] = useState({
    startTime: true,
    endTime: true,
    lastSuccessfulRun: true,
    lastFailure: true,
  });

  const { client } = useClient();

  const config = useConfig();
  const formatDate = config.get("user_format_date", config.get("format_date"));

  // The 'selectable' property is used to define if the
  // column is available in the 'manage columns' dropdown
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => [
    {
      Header: "Task",
      accessor: "task",
      sortType: "basic",
      Cell: (row) => <Link to={row.cell.value}>{row.cell.value}</Link>,
      selectable: false,
    },
    {
      Header: "Start Time",
      accessor: "startTime",
      sortType: "basic",
      Filter: (props) => <DateRangeFilter label="Start Time" {...props} />,
      Cell: ({ cell: { value } }) =>
        value ? (
          <TimeAgo title={formatDate(new Date(value))} date={value} />
        ) : (
          "-"
        ),
      show: selectedColumns["startTime"],
      selectable: true,
    },
    {
      Header: "End Time",
      accessor: "endTime",
      sortType: "basic",
      Filter: (props) => <DateRangeFilter label="End Time" {...props} />,
      Cell: ({ cell: { value } }) =>
        value ? (
          <TimeAgo title={formatDate(new Date(value))} date={value} />
        ) : (
          "-"
        ),
      show: selectedColumns["endTime"],
      selectable: true,
    },
    {
      Header: "Last Successful Run",
      accessor: "lastSuccessfulRun",
      sortType: "basic",
      Filter: (props) => (
        <DateRangeFilter label="Last Successful Run" {...props} />
      ),
      Cell: ({ cell: { value } }) =>
        value ? (
          <TimeAgo title={formatDate(new Date(value))} date={value} />
        ) : (
          "-"
        ),
      show: selectedColumns["lastSuccessfulRun"],
      selectable: true,
    },
    {
      Header: "Last Failure",
      accessor: "lastFailure",
      sortType: "basic",
      Filter: (props) => <DateRangeFilter label="Last Failure" {...props} />,
      Cell: ({ cell: { value } }) =>
        value ? (
          <TimeAgo title={formatDate(new Date(value))} date={value} />
        ) : (
          "-"
        ),
      show: selectedColumns["lastFailure"],
      selectable: true,
    },
  ]);

  const onFetchData = (options) => client(GET_LIST, "task-runs", options);

  return (
    <>
      <Helmet>
        <title>Task Runs | Peracto</title>
      </Helmet>

      <Heading name="Task Runs">
        <div className="ml-2 d-flex align-items-center">
          <Link className="btn btn-outline-primary" to="/tasks">
            <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
            Back to Tasks
          </Link>
        </div>
      </Heading>

      <div className="row">
        <div className="col">
          <div className="card card--transparent">
            <div className="card-body">
              <HydraTable
                columns={columns}
                selectedColumns={selectedColumns}
                onFetchData={onFetchData}
                onSelectColumn={onSelectColumn}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskRunsList;
