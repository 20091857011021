import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useConfig } from "@peracto/peracto-config";
import dayjs from "dayjs";

import { Form, Group, Input, Select, Tags } from "@peracto/peracto-ui";
import DateRange from "components/ui/DateRange";

export const OrderExportForm = (props) => {
    const { setShowOrderExportForm } = props;

    const [submitting, setSubmitting] = useState(false);

    const config = useConfig();
    const API_URL = config.get("api");

    const onCreateOrderExport = async (values) => {
        setSubmitting(true);
        const { after, before, keyword, deliveryOption, status } = values;
        const formattedAfter = after
            ? `${dayjs(after).format("YYYY-MM-DD")}T00:00:00`
            : "";
        const formattedBefore = before
            ? `${dayjs(before).format("YYYY-MM-DD")}T00:00:00`
            : "";

        const body = [];

        if (formattedAfter && formattedBefore) {
            body.push({
                type: "createdAt",
                value: `{"after":"${formattedAfter}", "before":"${formattedBefore}"}`,
            });
        }

        if (keyword?.length > 0) {
            body.push({
                type: "keyword",
                value: keyword,
            });
        }

        if (deliveryOption.length > 0) {
            body.push({
                type: "deliveryOption",
                value: deliveryOption,
            });
        }

        if (status) {
            body.push({
                type: "status",
                value: status.join(","),
            });
        }

        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        };
        try {
            await axios.post(
                `${API_URL}/export-logs`,
                {
                    type: "orders",
                    options: body,
                },
                {
                    headers,
                }
            );

            toast.success("Order export generating");
            setShowOrderExportForm(false);
        } catch (e) {
            console.error(e);

            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "There was an error when trying to create a order export."
            );
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div data-testid="generate-order-export">
            <Form
                autoComplete="off"
                values={{
                    after: "",
                    before: "",
                    keyword: "",
                    deliveryOption: [],
                    status: "",
                }}
                showActionPanel={false}
                onSubmit={onCreateOrderExport}
                {...props}
            >
                <Group
                    key="generate-order-export"
                    id="generate-order-export"
                    name="Generate order Export"
                >
                    <Input name="keyword" label="Keyword" testId="keyword" />

                    <Tags
                        name="status"
                        placeholder="Select Status..."
                        options={[
                            { label: "Successful", value: "successful" },
                            { label: "Processing", value: "processing" },
                            { label: "Error", value: "error" },
                        ]}
                        label="Status"
                        testId="status-dropdown"
                        testIdItems="status-dropdown__item"
                    />

                    <Select
                        name="deliveryOption"
                        placeholder="Select Delivery Option..."
                        options={[
                            {
                                label: "C - Send all items together",
                                value: "C",
                            },
                            {
                                label: "P - Send available items immediately",
                                value: "P",
                            },
                        ]}
                        label="Delivery Option"
                        testId="delivery-option-dropdown"
                        testIdItems="delivery-option-dropdown__item"
                    />

                    <DateRange
                        fromLabel="Start Date"
                        toLabel="End Date"
                        toName="before"
                        fromName="after"
                        help="Date range is limited to 7 days"
                        testId="date-range"
                    />

                    <button
                        className="btn btn-success"
                        data-testid="submit"
                        type="submit"
                        disabled={submitting}
                    >
                        Generate Export
                    </button>
                </Group>
            </Form>
        </div>
    );
};
