/*
 * API -> UI conversion
 --------------------------------------------------------*/
function prepareItemForTree(item) {
    if (item.linkText) {
        item.title = item.linkText
    }

    if (item.path) {
        item.pathName = item.path
    }

    if (item.children) {
        item.children = convertToTreeData(item.children)
    }

    if (item.displayFrom) {
        item.displayFrom = new Date(item.displayFrom)
    }

    if (item.displayTo) {
        item.displayTo = new Date(item.displayTo)
    }

    if (item?.content?.id) {
        item.identifier = item.content.id
    }

    return item
}

export const convertToTreeData = data => {
    if (data?.map) {
        return data.map(item => {
            return prepareItemForTree(item)
        })
    } else {
        return prepareItemForTree(data)
    }
}

/*
 * UI -> API conversion
 --------------------------------------------------------*/

export const convertToAPIData = data => {
    if (data?.length > 0) {
        return data.map(item => {
            let id

            if (typeof item.id === 'string') {
                // Newly created items' ID is an IRI (which is a string) e.g. "/menu-item/1"
                // originID gives us the created item's ID as an int
                id = item.originId
            } else {
                id = item.id
            }

            const apiItem = {
                id,
                children: item.children || [],
            }
            if (apiItem?.children?.length > 0) {
                apiItem.children = convertToAPIData(apiItem.children)
            }
            return apiItem
        })
    }
}
