import React, { useMemo, useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons/faPlusCircle";

import { Helmet } from "react-helmet";

import {
  HydraTable,
  Heading,
  TextFilter,
  SelectFilter,
  StyledCheckbox,
} from "@peracto/peracto-ui";

import { GET_LIST, useClient } from "@peracto/client";

const FormsList = () => {
  const { client } = useClient();

  // Default visible columns - key = column accessor
  const [selectedColumns, onSelectColumn] = useState({
    sendUserEmail: true,
    sendAdminEmail: true,
  });

  // The 'selectable' property is used to define if the
  // column is available in the 'manage columns' dropdown
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => [
    {
      id: "selection",
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <StyledCheckbox {...getToggleAllRowsSelectedProps()} />
      ),
      Cell: ({ row }) => {
        return (
          <StyledCheckbox
            {...row.getToggleRowSelectedProps()}
            data-testid={`row-${row.index}-checkbox`}
          />
        );
      },
      selectable: false,
    },
    {
      Header: "Label",
      accessor: "label",
      sortType: "basic",
      Filter: (props) => <TextFilter label="Label" {...props} />,
      Cell: (row) => <Link to={row.row.original.id}>{row.cell.value}</Link>,
      selectable: false,
    },
    {
      Header: "Submitted Forms",
      accessor: "label",
      Cell: ({ row }) => (
        <Link
          to={{
            pathname: `forms/submitted/${row.original.label.replace(
              /\s+/g,
              "+"
            )}`,
            state: { id: row.original.originId },
          }}
        >
          View List
        </Link>
      ),
      selectable: false,
    },

    {
      Header: "User Email",
      accessor: "sendUserEmail",
      sortType: "basic",
      Filter: (props) => (
        <SelectFilter
          label="User Email"
          options={[
            { value: "true", label: "Active" },
            { value: "false", label: "Inactive" },
          ]}
          {...props}
        />
      ),
      Cell: ({ cell: { value } }) => (
        <span
          className={classNames("badge", "badge-pill", {
            "badge-success": value,
            "badge-danger": !value,
          })}
        >
          {value ? "Active" : "Inactive"}
        </span>
      ),
      selectable: true,
      show: selectedColumns["sendUserEmail"],
    },
    {
      Header: "Admin Email",
      accessor: "sendAdminEmail",
      sortType: "basic",
      Filter: (props) => (
        <SelectFilter
          label="Admin Email"
          options={[
            { value: "true", label: "Active" },
            { value: "false", label: "Inactive" },
          ]}
          {...props}
        />
      ),
      Cell: ({ cell: { value } }) => (
        <span
          className={classNames("badge", "badge-pill", {
            "badge-success": value,
            "badge-danger": !value,
          })}
        >
          {value ? "Active" : "Inactive"}
        </span>
      ),
      selectable: true,
      show: selectedColumns["sendAdminEmail"],
    },
  ]);

  const onFetchData = (options) => {
    return client(GET_LIST, "forms", options);
  };

  return (
    <>
      <Helmet>
        <title>Forms | Peracto</title>
      </Helmet>
      <Heading name="Forms">
        <div className="ml-2 d-flex align-items-center">
          <Link
            className="btn btn-primary"
            to="/forms/add"
            data-testid="add-form"
          >
            <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
            Add Form
          </Link>
        </div>
      </Heading>

      <div className="row">
        <div className="col">
          <div className="card card--transparent">
            <div className="card-body">
              <HydraTable
                columns={columns}
                selectedColumns={selectedColumns}
                onFetchData={onFetchData}
                onSelectColumn={onSelectColumn}
                defaultFilters={{
                  label: "",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormsList;
