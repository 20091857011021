import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";

import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

import { Heading } from "@peracto/peracto-ui";
import { CREATE, useClient, getSchemaFromResource } from "@peracto/client";

import { MODE_ADD } from "../RedirectsForm";

const RedirectsAdd = ({ RedirectsForm }) => {
  const { client, getResource } = useClient();
  const [redirect, setRedirect] = useState();

  const schema = getSchemaFromResource(getResource("redirects")).shape({
    code: yup.mixed().required(),
    active: yup.mixed().required(),
    partialMatch: yup.mixed().required(),
  });

  const onSubmit = async (data, actions) => {
    try {
      const parsedData = {
        ...data,
        active: data.active === "true",
        partialMatch: data.partialMatch === "true",
        code: parseInt(data.code),
      };

      const response = await client(CREATE, "redirects", {
        data: parsedData,
      });

      if (response.data.violations && response.data.violations.length > 0) {
        // Display errors for invalid fields
        actions.setSubmitting(false);
        // eslint-disable-next-line array-callback-return
        response.data.violations.map((error) => {
          actions.setFieldError(error.propertyPath, error.message);
        });
      } else {
        setRedirect(response.data.id);
        toast.success("Redirect successfully added!");
        actions.setSubmitting(false);
      }
    } catch (e) {
      console.error(e);
      toast.error(
        e?.error?.body?.hasOwnProperty("hydra:description")
          ? e.error.body["hydra:description"]
          : "Whoops, there was a problem..."
      );
      if (e?.error?.body?.violations?.length > 0) {
        // Display errors for invalid fields
        // eslint-disable-next-line array-callback-return
        e.error.body.violations.map((error) => {
          actions.setFieldError(error.propertyPath, error.message);
        });
      }
      actions.setSubmitting(false);
    }
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <>
      <Helmet>
        <title>Redirects | Add | Peracto</title>
      </Helmet>
      <div className="form-container">
        <Heading name="Add Redirect">
          <div className="flex-grow-1 d-flex align-items-center justify-content-end">
            <Link
              className="btn btn-outline-primary"
              data-testid="back-to-redirects"
              to="/redirects"
            >
              <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
              Back to Redirects
            </Link>
          </div>
        </Heading>
        <RedirectsForm onSubmit={onSubmit} mode={MODE_ADD} schema={schema} />
      </div>
    </>
  );
};

export default RedirectsAdd;
