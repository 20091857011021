/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { Field, FieldArray } from "formik";
import Sortable from "react-sortablejs";
import classnames from "classnames";

import {
  Form,
  Group,
  Input,
  Select,
  Modal,
  FormActions,
} from "@peracto/peracto-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faGripLines } from "@fortawesome/pro-regular-svg-icons/faGripLines";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons/faExclamationTriangle";

import { toast } from "react-toastify";
import { SortableRow } from "@peracto/peracto-ui";

import { DELETE, useClient } from "@peracto/client";

import { inputTypeOptions } from "packages/peracto-common";

export const MODE_ADD = "add";
export const MODE_EDIT = "edit";

const INPUT_OPTION_TYPES = ["select", "radio", "multi-select"];

const getInputFieldType = (type) => {
  switch (type) {
    case "telephone":
      return "tel";
    case "date":
      return "date";
    case "email":
      return "email";
    default:
      return "text";
  }
};

const FormFieldsForm = ({ mode = MODE_EDIT, ...props }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [redirect, setRedirect] = useState();

  const { client } = useClient();

  const onDelete = async () => {
    try {
      await client(DELETE, "form-fields", {
        id: props.values.id,
      });

      toast.success("Form Field deleted successfully!");
      setRedirect("/form-fields");
    } catch (e) {
      console.error(e);
      toast.error(
        e?.error?.body?.hasOwnProperty("hydra:description")
          ? e.error.body["hydra:description"]
          : "Whoops, there was a problem..."
      );
    }
  };

  return (
    <>
      {redirect && <Redirect to={redirect} />}

      {mode === MODE_EDIT && (
        <FormActions>
          <a
            className="text-danger"
            onClick={() => setShowDialog(true)}
            data-testid="delete-form-field"
          >
            <FontAwesomeIcon icon={faTrash} className="mr-2" />
            Delete Form Field
          </a>
        </FormActions>
      )}

      <Form
        autoComplete="off"
        {...props}
        values={{
          label: "",
          helpText: "",
          inputType: null,
          inputOptions: [],
          defaultValue: "",
          thirdPartyFieldId: null,
          uniqueString: null,
          ...props.values,
        }}
      >
        <Group key="core" id="core" name="Core">
          <div data-testid="label-section">
            <Input
              name="label"
              label="Label"
              placeholder="Enter Label..."
              testId="label"
              required
            />
          </div>

          <div data-testid="help-text-section">
            <Input
              name="helpText"
              label="Help Text"
              placeholder="Enter Help Text..."
              testId="help-text"
            />
          </div>

          <div data-testid="input-section">
            <Select
              name="inputType"
              label="Input Type"
              placeholder="Please select..."
              required
              options={inputTypeOptions}
              testId="input-type"
            />

            <Field name="inputOptions">
              {({ field, form }) => (
                <>
                  {INPUT_OPTION_TYPES.includes(form.values.inputType) && (
                    <>
                      <FieldArray name={field.name}>
                        {() => (
                          <>
                            <label>Input Options</label>
                            <table className="table mb-1 table-sm">
                              <colgroup>
                                <col width="5%" />
                                <col width="80%" />
                                <col width="15%" />
                              </colgroup>

                              <thead>
                                <tr>
                                  <th></th>
                                  <th>Value</th>
                                  <th className="text-right">Remove</th>
                                </tr>
                              </thead>

                              <Sortable
                                tag="tbody"
                                onChange={(order, sortable, evt) => {
                                  const values = [...field.value];
                                  const movedItem = values[evt.oldIndex];
                                  const to = evt.newIndex;
                                  values.splice(evt.oldIndex, 1);
                                  values.splice(to, 0, movedItem);
                                  form.setFieldValue("inputOptions", values);
                                }}
                                options={{
                                  animation: 100,
                                }}
                              >
                                {field.value && field.value.length > 0 ? (
                                  field.value.map((item, index) => (
                                    <SortableRow key={index} data-id={index}>
                                      <td data-testid={`row-${index}-slider`}>
                                        <FontAwesomeIcon icon={faGripLines} />
                                      </td>
                                      <td data-testid={`row-${index}-value`}>
                                        {item}
                                      </td>
                                      <td className="text-right">
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-danger"
                                          data-testid={`row-${index}-remove`}
                                          onClick={() => {
                                            const fieldVal = field.value || [];
                                            const values = [...fieldVal];
                                            values.splice(index, 1);
                                            form.setFieldValue("inputOptions", [
                                              ...values,
                                            ]);

                                            // Clear defaultValue too if it's being removed from the form
                                            if (
                                              form.values.defaultValue === item
                                            ) {
                                              form.setFieldValue(
                                                "defaultValue",
                                                ""
                                              );
                                            }
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                      </td>
                                    </SortableRow>
                                  ))
                                ) : (
                                  <tr>
                                    <td
                                      colSpan="3"
                                      className={classnames(
                                        "text-center",
                                        "py-2",
                                        {
                                          "text-danger":
                                            form.errors[field.name],
                                        }
                                      )}
                                    >
                                      {form.errors[field.name] ? (
                                        <>
                                          {" "}
                                          {form.values.inputOptions.length === 0
                                            ? "This Input Type requires options to be created. Please update and try again."
                                            : form.errors[field.name]}{" "}
                                        </>
                                      ) : (
                                        "Add some Input Options"
                                      )}
                                    </td>
                                  </tr>
                                )}
                              </Sortable>
                            </table>
                          </>
                        )}
                      </FieldArray>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <input
                              type="text"
                              id="inputOptions"
                              className="form-control"
                              data-testid="input-option-field"
                              placeholder="Enter an input option"
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault();
                                  if (e.target.value.length > 0) {
                                    const fieldVal = field.value || [];
                                    const values = [...fieldVal];
                                    values.push(e.target.value);
                                    form.setFieldValue("inputOptions", [
                                      ...values,
                                    ]);
                                    e.target.value = "";
                                  }
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </Field>

            <Field name="defaultValue">
              {({ field, form }) => (
                <>
                  {!["checkbox", "file", "multi-select"].includes(
                    form.values.inputType
                  ) && (
                    <>
                      {INPUT_OPTION_TYPES.includes(form.values.inputType) ? (
                        <Select
                          name={field.name}
                          label="Default Value"
                          placeholder="Please select..."
                          testId="default-value-dropdown"
                          required
                          options={
                            form.values.inputOptions &&
                            form.values.inputOptions.map((option) => ({
                              label: option,
                              value: option,
                            }))
                          }
                          allowClear={true}
                        />
                      ) : (
                        <Input
                          name={field.name}
                          type={getInputFieldType(form.values.inputType)}
                          label="Default Value"
                          placeholder="Enter Default Value..."
                          testId="default-value"
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </Field>
          </div>

          <div data-testid="third-party-section">
            <Field name="thirdPartyFieldId">
              {({ form }) => (
                <Input
                  name="thirdPartyFieldId"
                  label="Third Party Field ID"
                  placeholder="Enter Third Party Field ID..."
                  testId="third-party-field-id"
                  onChange={(e) => {
                    if (e.target.value === "") {
                      form.setFieldValue("thirdPartyFieldId", null);
                    } else {
                      form.setFieldValue("thirdPartyFieldId", e.target.value);
                    }
                  }}
                />
              )}
            </Field>
          </div>

          <div data-testid="unique-string-section">
            <Field name="uniqueString">
              {({ form }) => (
                <Input
                  name="uniqueString"
                  label="Unique String"
                  placeholder="Enter Unique String.."
                  testId="unique-string"
                  onChange={(e) => {
                    if (e.target.value === "") {
                      form.setFieldValue("uniqueString", null);
                    } else {
                      form.setFieldValue("uniqueString", e.target.value);
                    }
                  }}
                />
              )}
            </Field>
          </div>
        </Group>
      </Form>

      {mode === MODE_EDIT && (
        <Modal
          isVisible={showDialog}
          title="Delete Form Field"
          close={() => setShowDialog(false)}
          buttons={[
            {
              type: "btn-outline-secondary",
              text: "Close",
              action: () => setShowDialog(false),
            },
            {
              type: "btn-danger",
              text: "Delete Form Field",
              action: () => onDelete(),
            },
          ]}
        >
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            size="4x"
            className="mb-4 d-block"
          />
          Are you sure you would like to permanently delete this form field?
          Deleted form fields cannot be recovered.
        </Modal>
      )}
    </>
  );
};

FormFieldsForm.displayName = "FormFieldsForm";

FormFieldsForm.propTypes = {
  values: PropTypes.object,
  mode: PropTypes.oneOf([MODE_ADD, MODE_EDIT]),
  schema: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default FormFieldsForm;
