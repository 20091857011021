import React, { useState, useEffect } from 'react'
import SortableTree, {
    addNodeUnderParent,
    removeNodeAtPath,
    changeNodeAtPath,
} from 'react-sortable-tree'
import 'react-sortable-tree/style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons/faPlusCircle'
import { faSave } from '@fortawesome/pro-regular-svg-icons/faSave'
import { faEdit } from '@fortawesome/pro-regular-svg-icons/faEdit'
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle'

import { CREATE, UPDATE, DELETE, useClient } from '@peracto/client'
import { Modal } from '@peracto/peracto-ui'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'

import NavigationTreeForm from './NavigationTreeForm'
import NavigationTreeRow from './NavigationTreeRow'
import NavigationTreeEmpty from './NavigationTreeEmpty'
import { convertToTreeData } from '../../NavigationEdit/util'
import * as S from './styled'

const CHILDESS_TYPES = ['directLink']
const CONTENT_TYPES = ['page', 'article']

const NavigationTree = ({ values, menuCode, onSaveOrder, savingOrder = false }) => {
    const { client } = useClient()
    const [treeData, setTreeData] = useState(values)
    const [showAddModal, setShowAddModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [modalData, setModalData] = useState({})

    const onSubmitForm = async (data, actions) => {
        let parent = null
        if (modalData?.parent) {
            parent = modalData.parent['@id']
        }

        try {
            const parsedData = {
                ...data,
                parent,
                menuCode,
                displayFrom: data.displayFrom ? dayjs(data.displayFrom).format() : null,
                displayTo: data.displayTo ? dayjs(data.displayTo).format() : null,
                itemType: CONTENT_TYPES.includes(data.itemType) ? 'content' : data.itemType,
            }

            if (!parent) {
                delete parsedData.parent
            }

            // Branches don't require a path and the API can't handle a null path
            if (parsedData.itemType === 'branch' && parsedData?.path === null) {
                delete parsedData.path
            }

            let method = CREATE

            const options = {
                data: parsedData,
            }

            const isUpdate = data.hasOwnProperty('@id')

            // Item can only have '@id' if it's an existing item
            if (isUpdate) {
                method = UPDATE
                options.id = data['@id']
                delete parsedData.parent // we don't need to pass the parent when updating
            }

            const response = await client(method, 'menu-items', options)

            if (modalData.path) {
                if (isUpdate) {
                    // UPDATE existing node
                    setTreeData(
                        changeNodeAtPath({
                            treeData,
                            path: modalData.path,
                            getNodeKey,
                            newNode: convertToTreeData(data),
                        })
                    )
                } else {
                    // CREATE new node as child
                    setTreeData(
                        addNodeUnderParent({
                            treeData,
                            parentKey: modalData.path[modalData.path.length - 1],
                            expandParent: true,
                            getNodeKey,
                            newNode: convertToTreeData(response.data),
                            addAsFirstChild: false,
                        }).treeData
                    )
                }
            } else {
                // CREATE new node at root
                setTreeData([...treeData, convertToTreeData(response.data)])
            }

            toast.success(`Item successfully ${isUpdate ? 'updated' : 'added'}!`)
            actions.setSubmitting(false)
            actions.resetForm()
            setShowAddModal(false)
            setModalData({})
        } catch (e) {
            console.error(e)
            toast.error(
                e?.error?.body?.hasOwnProperty('hydra:description')
                    ? e.error.body['hydra:description']
                    : 'Whoops, there was a problem...'
            )
            actions.setSubmitting(false)
        }

        setShowAddModal(false)
    }

    const onDelete = async () => {
        try {
            await client(DELETE, 'menu-items', {
                id: `/menu-items/${modalData.node.originId || modalData.node.id}`,
            })

            setTreeData(
                removeNodeAtPath({
                    treeData,
                    path: modalData.path,
                    getNodeKey,
                })
            )

            toast.success(`${modalData.title || 'Menu item'} deleted successfully!`)
            setModalData(null)
        } catch (e) {
            console.error(e)
            toast.error(
                e?.error?.body?.hasOwnProperty('hydra:description')
                    ? e.error.body['hydra:description']
                    : 'Whoops, there was a problem...'
            )
        }
    }

    useEffect(() => {
        setTreeData(values)
    }, [values])

    const getNodeKey = ({ treeIndex }) => treeIndex

    if (treeData) {
        return (
            <>
                <S.NavigationTree>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                setModalData({})
                                setShowAddModal(true)
                            }}
                        >
                            <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                            Add Item
                        </button>

                        <button
                            className={`btn btn-success ml-2 ${savingOrder && 'btn-disabled'}`}
                            onClick={e => {
                                e.preventDefault()
                                onSaveOrder([...treeData])
                            }}
                            disabled={savingOrder || treeData.length === 0}
                        >
                            <FontAwesomeIcon icon={faSave} className="mr-2" />
                            {savingOrder ? 'Saving...' : 'Save Display Order'}
                        </button>
                    </div>
                    {treeData.length > 0 ? (
                        <div style={{ height: '80vh', minHeight: '400px' }}>
                            <SortableTree
                                isVirtualized={false}
                                treeData={treeData}
                                onChange={treeData => setTreeData(treeData)}
                                nodeContentRenderer={NavigationTreeRow}
                                canNodeHaveChildren={node => {
                                    return node && !CHILDESS_TYPES.includes(node.itemType)
                                }}
                                generateNodeProps={({ node, path }) => {
                                    let buttons = [
                                        <button
                                            className="btn btn-link text-info"
                                            title="Edit Menu Item"
                                            onClick={() => {
                                                setModalData({ node, path })
                                                setShowAddModal(true)
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faEdit} />
                                        </button>,

                                        <button
                                            className="btn btn-link text-danger"
                                            title="Remove Item"
                                            onClick={() => {
                                                setModalData({ node, path })
                                                setShowDeleteModal(true)
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>,
                                    ]

                                    // If the menu item supports children then add the 'Add Child'
                                    // button between the edit/delete buttons
                                    if (!CHILDESS_TYPES.includes(node?.itemType)) {
                                        buttons.splice(
                                            1,
                                            0,
                                            <button
                                                className="btn btn-link"
                                                title="Add Child"
                                                onClick={() => {
                                                    setModalData({
                                                        node: null,
                                                        path,
                                                        parent: node,
                                                    })
                                                    setShowAddModal(true)
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faPlusCircle} />
                                            </button>
                                        )
                                    }
                                    return {
                                        buttons,
                                    }
                                }}
                            />
                        </div>
                    ) : (
                        <NavigationTreeEmpty {...{ setShowAddModal, setModalData }} />
                    )}
                </S.NavigationTree>

                <Modal
                    isVisible={showAddModal}
                    title={`${modalData?.node ? 'Edit' : 'Add'} Menu Item`}
                    close={() => setShowAddModal(false)}
                >
                    {showAddModal && (
                        <NavigationTreeForm
                            values={modalData?.node || {}}
                            parent={modalData?.parent}
                            onSubmitForm={onSubmitForm}
                            childessTypes={CHILDESS_TYPES}
                            onClose={() => setShowAddModal(false)}
                        />
                    )}
                </Modal>

                <Modal
                    isVisible={showDeleteModal}
                    title="Delete Menu Item"
                    close={() => setShowDeleteModal(false)}
                    buttons={[
                        {
                            type: 'btn-outline-secondary',
                            text: 'Close',
                            action: () => {
                                setShowDeleteModal(false)
                            },
                        },
                        {
                            type: 'btn-danger',
                            text: 'Delete',
                            action: () => {
                                setShowDeleteModal(false)
                                onDelete()
                            },
                        },
                    ]}
                >
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        size="4x"
                        className="d-block mb-4"
                    />
                    {`Are you sure you would like to remove ${modalData?.node?.title ||
                        'this item'} from the menu?`}
                </Modal>
            </>
        )
    }

    return 'Loading...'
}

export default NavigationTree
