import { toast } from 'react-toastify'
import axios from 'axios'

export const onRunTask = async (apiUrl, taskId) => {
    try {
        await axios.get(`${apiUrl}/tasks/${taskId}/run`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        })
        toast.success(`The ${taskId} task will begin shortly.`)
    } catch (e) {
        console.error(e)
        toast.error(
            e?.error?.body?.hasOwnProperty('hydra:description')
                ? `Error: ${e.error.body['hydra:description']}`
                : `There was a problem when attempting to run the ${taskId} task.`
        )
    }
}
