import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/pro-regular-svg-icons/faEdit";
import { toast } from "react-toastify";

import { Heading, StyledCheckbox, ActionPanel } from "@peracto/peracto-ui";
import { GET_LIST, UPDATE, useClient } from "@peracto/client";
import styled from "styled-components";

const StyledTable = styled.table`
  &.table {
    tbody {
      border-left: 1px solid #dee2e6;
      border-right: 1px solid #dee2e6;
      border-bottom: 1px solid #dee2e6;
      tr {
        background-color: #fff;
        height: 56px;
      }

      td {
        vertical-align: middle;
      }
    }
  }
`;

const CountriesList = () => {
  const [countries, setCountries] = useState([]);
  const [changedCountries, setChangedCountries] = useState([]);
  const [showActionPanel, setShowActionPanel] = useState(false);

  const { client } = useClient();

  const onSaveCountries = async () => {
    const formattedCountries = {};

    changedCountries.forEach(({ iso3, validForBilling, validForDelivery }) => {
      formattedCountries[iso3] = {
        validForBilling,
        validForDelivery,
      };
    });

    try {
      await client(UPDATE, "countries", {
        id: "countries",
        data: {
          countries: formattedCountries,
        },
      });

      toast.success("Countries successfully updated!");
      setShowActionPanel(false);
      setChangedCountries([]);
    } catch (e) {
      console.error(e);
      toast.error(
        e?.error?.body?.hasOwnProperty("hydra:description")
          ? e.error.body["hydra:description"]
          : "Whoops, there was a problem..."
      );
      setShowActionPanel(false);
    }
  };

  const onFetchData = async () => {
    const { data } = await client(GET_LIST, "countries", {
      pagination: {
        perPage: 1000,
      },
    });
    setCountries(data);
    setShowActionPanel(false);
    setChangedCountries([]);
  };

  useEffect(() => {
    onFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>Countries | Peracto</title>
      </Helmet>
      <Heading name="Countries" />

      <div className="row">
        <div className="col">
          <div className="card card--transparent">
            <div className="card-body">
              <StyledTable className="table">
                <thead>
                  <tr>
                    <th className="border-0 font-weight-normal">Country</th>
                    <th className="border-0 font-weight-normal">ISO3</th>
                    <th className="border-0 font-weight-normal">
                      Valid for Billing
                    </th>
                    <th className="border-0 font-weight-normal">
                      Valid for Delivery
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {countries.map((country, idx) => (
                    <tr key={country.iso3}>
                      <td>{country.label}</td>
                      <td>{country.iso3}</td>
                      <td>
                        <StyledCheckbox
                          checked={country.validForBilling}
                          onChange={(e) => {
                            const countryClone = [...countries];
                            countryClone[idx].validForBilling =
                              e.target.checked;

                            const alreadyChangedIdx =
                              changedCountries.findIndex(
                                (changed) => changed.iso3 === country.iso3
                              );

                            if (alreadyChangedIdx > -1) {
                              const changedClone = [...changedCountries];
                              changedClone[alreadyChangedIdx].validForBilling =
                                e.target.checked;
                              setChangedCountries(changedClone);
                            } else {
                              setChangedCountries([
                                ...changedCountries,
                                {
                                  ...country,
                                  validForBilling: e.target.checked,
                                },
                              ]);
                            }

                            setCountries(countryClone);
                            setShowActionPanel(true);
                          }}
                        />
                      </td>
                      <td>
                        <StyledCheckbox
                          checked={country.validForDelivery}
                          onChange={(e) => {
                            const countryClone = [...countries];
                            countryClone[idx].validForDelivery =
                              e.target.checked;

                            const alreadyChangedIdx =
                              changedCountries.findIndex(
                                (changed) => changed.iso3 === country.iso3
                              );

                            if (alreadyChangedIdx > -1) {
                              const changedClone = [...changedCountries];
                              changedClone[alreadyChangedIdx].validForDelivery =
                                e.target.checked;
                              setChangedCountries(changedClone);
                            } else {
                              setChangedCountries([
                                ...changedCountries,
                                {
                                  ...country,
                                  validForDelivery: e.target.checked,
                                },
                              ]);
                            }
                            setCountries(countryClone);
                            setShowActionPanel(true);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </StyledTable>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <ActionPanel isVisible={showActionPanel}>
            <p>
              <FontAwesomeIcon icon={faEdit} size="lg" className="mx-2" />
              You've made changes to this table.
            </p>

            <div className="d-flex align-items-center">
              <button
                type="button"
                className="btn btn-success"
                data-testid="save-form"
                onClick={() => {
                  onSaveCountries();
                }}
              >
                Save
              </button>
            </div>
          </ActionPanel>
        </div>
      </div>
    </>
  );
};

export default CountriesList;
