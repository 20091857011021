import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";
import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons/faCircleNotch";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";

import styled from "styled-components";
import { Helmet } from "react-helmet";
import dayjs from "dayjs";

import {
  Heading,
  NotFoundMessage,
  UnauthorisedMessage,
} from "@peracto/peracto-ui";

import { GET_ONE, useClient } from "@peracto/client";
import { useLocation } from "react-router-dom";

const TableHeader = styled.h3`
  color: #c8c8c8;
  font-size: 0.8rem;
  padding-bottom: 0.5rem;
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 0;
`;

const getFieldValue = (fieldValue) => {
  if (Array.isArray(fieldValue)) {
    return fieldValue.join(", ");
  } else if (typeof fieldValue === "boolean") {
    return <FontAwesomeIcon icon={!!fieldValue ? faCheck : faTimes} />;
  } else if (typeof fieldValue === "string" && fieldValue.startsWith("http")) {
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a href={fieldValue} target="_blank">
        {fieldValue}
      </a>
    );
  } else {
    return fieldValue;
  }
};

const FormSubmissionsViewContainer = ({ label, children }) => {
  const location = useLocation();
  const from = location.state?.from || null;
  const id = location.state?.id || null;
  return (
    <div className="form-container">
      <Heading name={label}>
        <div className="flex-grow-1 d-flex align-items-center justify-content-end">
          <Link
            className="btn btn-outline-primary"
            to={{
              pathname: from,
              state: { id: id },
            }}
            data-testid="back-to-form-submissions"
          >
            <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
            Back to Form Submissions
          </Link>
        </div>
      </Heading>
      {children}
    </div>
  );
};

const FormSubmissionsView = (props) => {
  const { id } = props.match.params;
  const { client } = useClient();
  const [loading, setLoading] = useState(true);
  const [unauthorised, setUnauthorised] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [redirect, setRedirect] = useState();

  const [submission, setSubmission] = useState();

  useEffect(() => {
    fetchSubmission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSubmission = async () => {
    try {
      const { data, response } = await client(GET_ONE, "form-submissions", {
        id: `/form-submissions/${id}`,
      });

      setSubmission({
        ...data,
        values: data.values["hydra:member"] || data?.values,
      });
      setLoading(false);

      if (response.status === 404) {
        setRedirect("/form-submissions");
      }
    } catch (e) {
      console.error(e);

      if (e.status === 403) {
        setUnauthorised(true);
      }

      if (e.status === 404) {
        setNotFound(true);
      }

      setLoading(false);
      setRedirect("/form-submissions");
    }
  };

  if (loading) {
    return (
      <FormSubmissionsViewContainer label="Loading...">
        <div className="card">
          <div className="py-5 card-body d-flex justify-content-center align-items-center">
            <FontAwesomeIcon icon={faCircleNotch} size="4x" spin />
          </div>
        </div>
      </FormSubmissionsViewContainer>
    );
  }

  if (unauthorised) {
    return <UnauthorisedMessage />;
  }

  if (notFound) {
    return (
      <NotFoundMessage
        url="/form-submissions"
        message="The form submission you're looking for could not be found"
        buttonLabel="Go to Form Submissions"
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {submission?.form?.label || "Submission"} | Form Submission | Peracto
        </title>
      </Helmet>
      <FormSubmissionsViewContainer label={submission?.form?.label}>
        {redirect && <Redirect to={redirect} />}
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              <span>
                Submitted At:{" "}
                <b data-testid="submitted-at-date">
                  {dayjs(new Date(submission?.submittedAt)).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                </b>
              </span>
            </div>

            {submission?.values?.length > 0 && (
              <>
                <TableHeader>Values</TableHeader>

                <table className="table table-sm">
                  <colgroup>
                    <col width="20%" />
                    <col width="20%" />
                    <col width="60%" />
                  </colgroup>

                  <thead>
                    <tr>
                      <th data-testid="label-header">Label</th>
                      <th data-testid="input-type-header">Input Type</th>
                      <th data-testid="value-header">Value</th>
                    </tr>
                  </thead>

                  <tbody>
                    {submission.values.map((value, idx) => (
                      <tr key={idx}>
                        <td data-testid={`row-${idx}-label`}>
                          {value.formField.label}
                        </td>
                        <td data-testid={`row-${idx}-input-type`}>
                          {value.formField.inputType}
                        </td>
                        <td
                          data-testid={`row-${idx}-value`}
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {getFieldValue(value.fieldValue)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
      </FormSubmissionsViewContainer>
    </>
  );
};

export default FormSubmissionsView;
