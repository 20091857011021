/* eslint-disable */

import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { HydraTable, Heading } from "@peracto/peracto-ui";
import { GET_LIST, useClient } from "@peracto/client";

const PromotionsMetrics = () => {
    // Default visible columns - key = column accessor
    const [selectedColumns, onSelectColumn] = useState({});

    const { client } = useClient();

    // The 'selectable' property is used to define if the
    // column is available in the 'manage columns' dropdown
    const columns = [
        {
            Header: "Identifier",
            accessor: "identifier",
            sortType: "basic",
            selectable: false,
        },
        {
            Header: "Order Count",
            accessor: "orderCount",
            sortType: "basic",
            selectable: false,
        },
        {
            Header: "User Count",
            accessor: "userCount",
            sortType: "basic",
            selectable: false,
        },
    ];

    const onFetchData = (options) =>
        client(GET_LIST, "promotions-metrics", options);

    return (
        <>
            <Helmet>
                <title>Promotion Metrics | Peracto</title>
            </Helmet>
            <Heading name="Promotion Metrics" />

            <div className="row">
                <div className="col">
                    <div className="card card--transparent">
                        <div className="card-body">
                            <HydraTable
                                columns={columns}
                                selectedColumns={selectedColumns}
                                onFetchData={onFetchData}
                                onSelectColumn={onSelectColumn}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PromotionsMetrics;
