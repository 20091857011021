import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import dayjs from "dayjs";

import { DateInput, Help } from "@peracto/peracto-ui";
import * as S from "./styled";

const dateBoundaries = (date) => {
    return {
        start: new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            0,
            0,
            0
        ),
        end: new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            23,
            59,
            59
        ),
    };
};

const datePlus7days = (day) => {
    const maxDate = dayjs(day).add(7, "day");

    return maxDate.toDate();
};

const DateRange = ({
    fromName,
    toName,
    fromLabel = "",
    toLabel = "",
    required = false,
    help = "",
}) => {
    return (
        <S.DatePickerInput className="form-group">
            <S.DatePickerContainer className="form-row">
                <Field name={fromName}>
                    {({ form }) => {
                        const maxDate = datePlus7days(form.values[fromName]);

                        return (
                            <>
                                <div className="col-12 col-md-6">
                                    <DateInput
                                        name={fromName}
                                        label={fromLabel}
                                        selectsStart
                                        startDate={form.values[fromName]}
                                        endDate={form.values[toName]}
                                        setTime={(date) => {
                                            return dateBoundaries(date).start;
                                        }}
                                        rawDate={true}
                                        required={required}
                                        onChange={(date) => {
                                            form.setFieldValue(fromName, date);
                                            form.setFieldValue(
                                                toName,
                                                datePlus7days(date)
                                            );
                                        }}
                                    />
                                </div>
                                <div className="col-12 col-md-6">
                                    <DateInput
                                        name={toName}
                                        label={toLabel}
                                        selectsEnd
                                        startDate={form.values[fromName]}
                                        endDate={form.values[toName]}
                                        minDate={form.values[fromName]}
                                        maxDate={maxDate}
                                        setTime={(date) =>
                                            dateBoundaries(date).end
                                        }
                                        rawDate={true}
                                        required={required}
                                    />
                                </div>

                                {help && (
                                    <div className="col-12">
                                        <Help
                                            className="mt-0 mb-2"
                                            id={`${fromName}Help`}
                                            help={help}
                                        />
                                    </div>
                                )}
                            </>
                        );
                    }}
                </Field>
            </S.DatePickerContainer>
        </S.DatePickerInput>
    );
};

DateRange.displayName = "DateRange";

DateRange.propTypes = {
    fromName: PropTypes.string.isRequired,
    toName: PropTypes.string.isRequired,
    fromLabel: PropTypes.string,
    toLabel: PropTypes.string,
    help: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    placeholder: PropTypes.string,
    className: PropTypes.string,
    show: PropTypes.string,
    prefix: PropTypes.string,
};

export default DateRange;
