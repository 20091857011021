import lazyLoad from "@loadable/component";
import createPlugin from "./createPlugin";
import EmbedRenderer from "./Renderer/EmbedRenderer";
const EmbedForm = lazyLoad(() => import("./Form/EmbedForm"));

const embedPlugin = (settings) =>
    createPlugin({
        Renderer: EmbedRenderer,
        Controls: EmbedForm,

        ...settings,
    });

const embed = embedPlugin();
export default embed;

export { embedPlugin, EmbedRenderer };
