import React from "react";
import { Route, Switch } from "react-router-dom";

import FormsList from "./Forms/Forms/FormsList";
import FormsAdd from "./Forms/Forms/FormsAdd";
import FormsEdit from "./Forms/Forms/FormsEdit";
import FormsForm from "./Forms/Forms/FormsForm";

import FormFieldsList from "./Forms/FormFields/FormFieldsList";
import FormFieldsAdd from "./Forms/FormFields/FormFieldsAdd";
import FormFieldsEdit from "./Forms/FormFields/FormFieldsEdit";
import FormFieldsForm from "./Forms/FormFields/FormFieldsForm";

import FormSubmissionsList from "./Forms/FormSubmissions/FormSubmissionsList";
import FormSubmissionsView from "./Forms/FormSubmissions/FormSubmissionsView";

import RedirectsList from "./Redirects/RedirectsList";
import RedirectsAdd from "./Redirects/RedirectsAdd";
import RedirectsEdit from "./Redirects/RedirectsEdit";
import RedirectsForm from "./Redirects/RedirectsForm";
import RedirectsImport from "./Redirects/RedirectsImport";
import RedirectsImportStatus from "./Redirects/RedirectsImportStatus";

import NavigationList from "./Navigation/NavigationList";
import NavigationEdit from "./Navigation/NavigationEdit";
import NavigationForm from "./Navigation/NavigationForm";

import TasksList from "./Tasks/Tasks/TasksList";
import TasksEdit from "./Tasks/Tasks/TasksEdit";
import TasksForm from "./Tasks/Tasks/TasksForm";

import TaskRunsList from "./Tasks/TaskRuns/TaskRunsList";

import CountriesList from "./CountriesList";
import FormsSubmitted from "./Forms/Forms/FormsSubmitted";
import FormsExport from "./Forms/Forms/FormsExport";

export {
  FormsList,
  FormsAdd,
  FormsEdit,
  FormsForm,
  FormFieldsList,
  FormFieldsAdd,
  FormFieldsEdit,
  FormFieldsForm,
  FormSubmissionsList,
  FormSubmissionsView,
  RedirectsList,
  RedirectsAdd,
  RedirectsEdit,
  RedirectsForm,
  RedirectsImport,
  RedirectsImportStatus,
  NavigationList,
  NavigationEdit,
  NavigationForm,
  TasksList,
  TasksEdit,
  TasksForm,
  TaskRunsList,
  CountriesList,
};

const defaults = {
  FormsList,
  FormsAdd,
  FormsEdit,
  FormsForm,

  FormFieldsList,
  FormFieldsAdd,
  FormFieldsEdit,
  FormFieldsForm,

  FormSubmissionsList,
  FormSubmissionsView,

  RedirectsList,
  RedirectsAdd,
  RedirectsEdit,
  RedirectsForm,
  RedirectsImport,
  RedirectsImportStatus,

  NavigationList,
  NavigationEdit,
  NavigationForm,

  TasksList,
  TasksEdit,
  TasksForm,

  TaskRunsList,

  CountriesList,
};

export default (options = {}) => {
  const components = { ...defaults, ...options };

  const {
    FormsList,
    FormsAdd,
    FormsEdit,
    FormsForm,

    FormFieldsList,
    FormFieldsAdd,
    FormFieldsEdit,
    FormFieldsForm,

    RedirectsList,
    RedirectsAdd,
    RedirectsEdit,
    RedirectsForm,
    RedirectsImport,
    RedirectsImportStatus,

    NavigationList,
    NavigationEdit,
    NavigationForm,

    TasksList,
    TasksEdit,
    TasksForm,

    TaskRunsList,

    CountriesList,
  } = components;

  return {
    routes: [
      {
        path: "/forms",
        render: ({ match }) => (
          <Switch>
            <Route path={`${match.url}`} exact component={FormsList} />
            <Route path={`${match.url}/add`} exact>
              {(props) => <FormsAdd {...props} FormsForm={FormsForm} />}
            </Route>
            <Route path={`${match.url}/:id`} exact>
              {(props) => <FormsEdit {...props} FormsForm={FormsForm} />}
            </Route>
            <Route path={`${match.url}/submitted/:label`} exact>
              {(props) => <FormsSubmitted {...props} />}
            </Route>
            <Route path={`${match.url}/submitted/:label/export`} exact>
              {(props) => <FormsExport {...props} />}
            </Route>
            <Route path={`${match.url}/submitted/:label/:id`} exact>
              {(props) => <FormSubmissionsView {...props} />}
            </Route>
          </Switch>
        ),
      },
      {
        path: "/form-fields",
        render: ({ match }) => (
          <Switch>
            <Route path={`${match.url}`} exact component={FormFieldsList} />
            <Route path={`${match.url}/add`} exact>
              {(props) => (
                <FormFieldsAdd {...props} FormFieldsForm={FormFieldsForm} />
              )}
            </Route>
            <Route path={`${match.url}/:id`} exact>
              {(props) => (
                <FormFieldsEdit {...props} FormFieldsForm={FormFieldsForm} />
              )}
            </Route>
          </Switch>
        ),
      },
      // {
      //   path: "/form-submissions",
      //   render: ({ match }) => (
      //     <Switch>
      //       <Route
      //         path={`${match.url}`}
      //         exact
      //         component={FormSubmissionsList}
      //       />
      //       <Route
      //         path={`${match.url}/:id`}
      //         exact
      //         component={FormSubmissionsView}
      //       />
      //     </Switch>
      //   ),
      // },

      {
        path: "/redirects",
        render: ({ match }) => (
          <Switch>
            <Route path={`${match.url}`} exact component={RedirectsList} />
            <Route
              path={`${match.url}/import`}
              exact
              component={RedirectsImport}
            />
            <Route
              path={`${match.url}/import/:id`}
              exact
              component={RedirectsImportStatus}
            />
            <Route path={`${match.url}/add`} exact>
              {(props) => (
                <RedirectsAdd {...props} RedirectsForm={RedirectsForm} />
              )}
            </Route>
            <Route path={`${match.url}/:redirect`} exact>
              {(props) => (
                <RedirectsEdit {...props} RedirectsForm={RedirectsForm} />
              )}
            </Route>
          </Switch>
        ),
      },

      {
        path: "/tasks",
        render: ({ match }) => (
          <Switch>
            <Route path={`${match.url}`} exact component={TasksList} />
            <Route
              path={`${match.url}/task-runs`}
              exact
              component={TaskRunsList}
            />
            <Route path={`${match.url}/:task`} exact>
              {(props) => <TasksEdit {...props} TasksForm={TasksForm} />}
            </Route>
          </Switch>
        ),
      },

      {
        path: "/countries",
        render: ({ match }) => (
          <Switch>
            <Route path={`${match.url}`} exact component={CountriesList} />
          </Switch>
        ),
      },

      {
        path: "/menus",
        render: ({ match }) => (
          <Switch>
            <Route path={`${match.url}`} exact component={NavigationList} />
            <Route path={`${match.url}/:menuCode`} exact>
              {(props) => (
                <NavigationEdit {...props} NavigationForm={NavigationForm} />
              )}
            </Route>
          </Switch>
        ),
      },
    ],
  };
};

export const inputTypeOptions = [
  { label: "Text", value: "text" },
  { label: "Textarea", value: "textarea" },
  { label: "Select", value: "select" },
  { label: "Radio", value: "radio" },
  { label: "Checkbox", value: "checkbox" },
  { label: "Checkbox Group", value: "multi-select" },
  { label: "File", value: "file" },
  { label: "Date", value: "date" },
  { label: "Declaration", value: "declaration" },
  { label: "Email", value: "email" },
  { label: "Telephone", value: "telephone" },
  { label: "Postcode", value: "postcode" },
  { label: "Hidden", value: "hidden" },
];
