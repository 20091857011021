import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";

import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import cloneDeep from "lodash/cloneDeep";

import { Heading } from "@peracto/peracto-ui";
import { CREATE, useClient, getSchemaFromResource } from "@peracto/client";

import { MODE_ADD } from "../FormsForm";

const FormsAdd = ({ FormsForm }) => {
  const { client, getResource } = useClient();
  const [redirect, setRedirect] = useState();

  const schema = getSchemaFromResource(getResource("forms")).shape({
    label: yup.string().required(),
    thirdPartyFormId: yup.string().nullable(),
    sendUserEmail: yup.boolean(),
    submitButtonText: yup.string().required(),
    redirectUrl: yup.string().required(),
    adminEmailSubject: yup
      .string()
      .nullable()
      .when("sendAdminEmail", {
        is: true,
        then: yup
          .string()
          .required(
            "Admin email subject must be set when 'Admin Email' is enabled"
          ),
      }),
    adminEmailContent: yup
      .string()
      .nullable()
      .when("sendAdminEmail", {
        is: true,
        then: yup
          .string()
          .required(
            "Admin email content must be set when 'Admin Email' is enabled"
          ),
      }),

    userEmailSubject: yup
      .string()
      .nullable()
      .when("sendUserEmail", {
        is: true,
        then: yup
          .string()
          .required(
            "User email subject must be set when 'User Email' is enabled"
          ),
      }),
    userEmailContent: yup
      .string()
      .nullable()
      .when("sendUserEmail", {
        is: true,
        then: yup
          .string()
          .required(
            "User email content must be set when 'User Email' is enabled"
          ),
      }),
    userEmailField: yup
      .string()
      .nullable()
      .when("sendUserEmail", {
        is: true,
        then: yup
          .string()
          .required(
            "User email field must be set when 'User Email' is enabled"
          ),
      }),
    adminEmailRcpt: yup
      .string()
      .nullable()
      .when("sendAdminEmail", {
        is: true,
        then: yup
          .string()
          .required(
            "Admin email field must be set when 'Admin Email' is enabled"
          ),
      }),
  });

  const onSubmit = async (data, actions) => {
    const dataClone = cloneDeep(data);

    dataClone.formFieldLinks &&
      dataClone.formFieldLinks.forEach((item, idx) => {
        item.formField = item.formField["@id"];
        item.displayOrder = idx + 1;

        item["@id"] && delete item["@id"];
        item["@type"] && delete item["@type"];
      });

    dataClone.sendAdminEmail = data.sendAdminEmail === true;
    dataClone.sendUserEmail = data.sendUserEmail === true;

    try {
      const response = await client(CREATE, "forms", {
        data: dataClone,
      });

      if (response.data.violations && response.data.violations.length > 0) {
        // Display errors for invalid fields
        actions.setSubmitting(false);
        // eslint-disable-next-line array-callback-return
        response.data.violations.map((error) => {
          actions.setFieldError(error.propertyPath, error.message);
        });
      } else {
        setRedirect(response.data.id);
        toast.success("Form successfully added!");
        actions.setSubmitting(false);
      }
    } catch (e) {
      console.error(e);
      if (e?.error?.body?.violations && e.error.body.violations.length > 0) {
        // Display errors for invalid fields
        actions.setSubmitting(false);
        // eslint-disable-next-line array-callback-return
        e.error.body.violations.map((error) => {
          actions.setFieldError(error.propertyPath, error.message);
        });
      }
      toast.error(
        e?.error?.body?.hasOwnProperty("hydra:description")
          ? e.error.body["hydra:description"]
          : "Whoops, there was a problem..."
      );
      actions.setSubmitting(false);
      actions.setValues(dataClone);
    }
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <>
      <Helmet>
        <title>Forms | Add | Peracto</title>
      </Helmet>
      <div className="form-container">
        <Heading name="Add Form">
          <div className="flex-grow-1 d-flex align-items-center justify-content-end">
            <Link
              className="btn btn-outline-primary"
              to="/forms"
              data-testid="back-to-forms"
            >
              <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
              Back to Forms
            </Link>
          </div>
        </Heading>
        <FormsForm onSubmit={onSubmit} mode={MODE_ADD} schema={schema} />
      </div>
    </>
  );
};

export default FormsAdd;
