import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";

import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

import { Heading } from "@peracto/peracto-ui";
import { CREATE, useClient, getSchemaFromResource } from "@peracto/client";

import { MODE_ADD, LKQ_USER_ID_GROUPS } from "../UserForm";
import { formatDataForAPI } from "../util";

export const UserAdd = ({ UserForm }) => {
    const { client, getResource } = useClient();
    const [redirect, setRedirect] = useState();

    const schema = getSchemaFromResource(getResource("users")).shape({
        email: yup.string().required("Email is required."),
        tradingCountry: yup.string().required("Trading Country is required."),
        title: yup.string().nullable(),
        telephone: yup.string().nullable(),
        babbageAccounts: yup
            .array()
            .min(1, "At least 1 Babbage account must be assigned to the user.")
            .required(),
        lkqUserId: yup.string().when("userGroup", (userGroup, schema) => {
            return LKQ_USER_ID_GROUPS.includes(userGroup)
                ? schema.required()
                : schema.notRequired();
        }),
    });

    const onSubmit = async (data, actions) => {
        const formattedData = formatDataForAPI(data);
        try {
            const response = await client(CREATE, "users", {
                data: formattedData,
            });

            if (
                response.data.violations &&
                response.data.violations.length > 0
            ) {
                // Display errors for invalid fields
                actions.setSubmitting(false);
                response.data.violations.forEach((error) => {
                    actions.setFieldError(
                        `${error.propertyPath}`,
                        error.message
                    );
                });
            } else {
                setRedirect(response.data.id);
                toast.success("User successfully added!");
                actions.setSubmitting(false);
            }
        } catch (e) {
            console.error(e);

            // eslint-disable-next-line no-unused-expressions
            e?.error?.body?.violations?.forEach((error) => {
                actions.setFieldError(`${error.propertyPath}`, error.message);
            });

            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "Whoops, there was a problem..."
            );
            actions.setSubmitting(false);
        }
    };

    if (redirect) {
        return <Redirect to={redirect} />;
    }

    return (
        <>
            <Helmet>
                <title>Users | Add | Peracto</title>
            </Helmet>

            <div className="form-container">
                <Heading name="Add User">
                    <div className="flex-grow-1 d-flex align-items-center justify-content-end">
                        <Link className="btn btn-outline-primary" to="/users">
                            <FontAwesomeIcon
                                icon={faArrowCircleLeft}
                                className="mr-2"
                            />
                            Back to Users
                        </Link>
                    </div>
                </Heading>

                <UserForm
                    onSubmit={onSubmit}
                    mode={MODE_ADD}
                    schema={schema}
                    testId="add"
                    values={{
                        title: "",
                        firstName: "",
                        lastName: "",
                        email: "",
                        telephone: "",
                        companyName: "",
                        type: null,
                        hasCreditTerms: true,
                        businessTelephone: "",
                        splitDateOfBirth: {
                            day: "",
                            month: "",
                            year: "",
                        },
                        status: "active",
                        roles: ["ROLE_USER"],
                        canShowPrices: true,
                        canManagePrice: true,
                        canEditAccount: true,
                        allowOrdersStraightToPrint: true,
                        allowAlternateDeliveryAddress: false,
                        hasHaynesPro: false,
                        hasFifm: false,
                        isExcludedFromOmnirewards: false,
                        excludedFromMaintenanceMode: false,
                        hasWismo: true,
                        babbageAccounts: [],
                        userGroup: "trade",
                        tradingCountry: "gb",
                        lkqUserId: "",
                    }}
                />
            </div>
        </>
    );
};
